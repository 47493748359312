import React from 'react';
import {ICategory} from "../../../interfaces/Category";
import {IField} from "../../../interfaces/IField";
import AdminCrud from "../../../component/admincrud/AdminCrud";
export default function Categories(){
    // @ts-ignore
    const columns: IField<ICategory>[] = [
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.category > b.category ? 1 : -1,
            show:true,
            update:true,
        },
    ];
  return (
      <AdminCrud<ICategory> entity="category" columnsToAdd={columns} menu={'categories'}/>
  );
}