import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    Form,
    Image,
    Input,
    message,
    Modal,
    notification, Popconfirm,
    Row,
    Select,
    Space,
    Upload,
} from 'antd';
import {IField} from "../../../interfaces/IField";
import {rootServiceURL} from "../../../config";
import axios from "axios";
import {openNotification} from "../../../util/OpenNotification";
import {ICountry} from "../../../interfaces/Country";
import {base64FromArrayBuffer, getUserToken, logOut} from "../../../util/Utils";
import AdminMenu from "../AdminMenu";
import PhocusTable from "../../../component/PhocusTable/PhocusTable";
import styles from "../../../component/admincrud/AdminCrud.module.css";
import {IEtradeDeskUpdate} from "../../../interfaces/IEtradeDeskUpdate";
import {useHistory} from "react-router-dom";
import {handleFileListChange, handlePreview, uploadButton} from "../../../component/Commons";
import {verifyCompany} from "../companies/action";


function EtradeDesk(){
    const [api, contextHolder] = notification.useNotification();
    const history = useHistory();

    const [countries, setCountries] = useState<ICountry[]>([]);
    const [etradeDesks, setEtradeDesks] = useState<IEtradeDeskUpdate[]>([]);
    const [formUpdate] = Form.useForm();
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);
    const [updateFieldValues, setUpdateFieldValues] = useState<IEtradeDeskUpdate | undefined>(undefined);

    const [fileList, setFileList] = useState<any[]>([]);
    const [file2List, setFile2List] = useState<any[]>([]);
    const [file4List, setFile4List] = useState<any[]>([]);

    const [preview, setPreview] = useState<{
        previewOpen: boolean,
        previewImage: string | undefined,
        previewTitle: string | undefined
    } | null>(null);

    const [memberImage, setMemberImage] = useState(null);
    const [member2Image, setMember2Image] = useState(null);
    const [etradeDeskImage, setEtradeDeskImage] = useState(null);

    useEffect(() => {
        loadCountries();
        loadEtradeDesks();
    }, []);

    const loadEtradeDesks = () => {
        const url = rootServiceURL() + `completegraph/etradedesk/getEtradeDesk`
        const criteriaItems = {
            "criteriaItems":[]
        }
        axios.post(url, criteriaItems, {headers: {token: getUserToken()}}).then(response => {
            if(response.status == 200){
                setEtradeDesks(response.data);
            }
            else{
                openNotification(api,'error', 'top', "An error has occurred loading etrade desks")
            }
        }).catch(reason => {
            openNotification(api,'error', 'top', "An error has occurred loading etrade desks "+(reason.response ? reason.response.data : reason));
        });
    }

    const loadCountries = () => {
        const url = rootServiceURL() + "minimalgraph/rest/search/Country"
        const criteriaItems = {
            "criteriaItems":[]
        }
        axios.post(url, criteriaItems, {headers: {token: "thisIsAValidTokenButDontUseIt"}}).then(response => {
            if(response.status == 200){
                setCountries(response.data);
            }
            else{
                openNotification(api,'error', 'top', "An error has occurred loading categories")
            }
        }).catch(reason => {
            openNotification(api,'error', 'top', "An error has occurred loading categories "+(reason.response ? reason.response.data : reason));
        });
    }

    const fields: IField<IEtradeDeskUpdate>[] = [
        {
            title: 'Entity',
            dataIndex: 'company',
            key: 'company',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.company.localeCompare(b.company),
            update: true,
            show:true,
        },
        {
            title: 'First Name',
            dataIndex: 'registereduserfirstname',
            key: 'registereduserfirstname',
            sorter: (a, b) => a.registereduserfirstname.localeCompare(b.registereduserfirstname),
            update:true,
            show:true,
        },
        {
            title: 'Last Name',
            dataIndex: 'registereduserlastname',
            key: 'registereduserlastname',
            sorter: (a, b) => a.registereduserlastname.localeCompare(b.registereduserlastname),
            update:true,
            show:true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            show:true,
        },
        {
            title: 'Desk Number',
            dataIndex: 'desknumber',
            key: 'desknumber',
            sorter: (a, b) => a.desknumber == null ? -1 : b.desknumber == null ? 1 : (a.desknumber > b.desknumber ? 1 : -1),
            update:true,
            show:true,
        },

        {
            title: 'Country',
            dataIndex: 'countryid',
            key: 'countryid',
            update:true,
            show:true,
            foreignkey:true,
            descriptiveColumn:"country",
            render: (countryid) => {
                return countryid? countryid.country : ''
            },
            // @ts-ignore
            renderInput: () => (
                <Select options={countries.map(country => ({
                        value : country.countryid,
                        label: country.country
                    }
                ))}/>
            ),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            update:true,
            show:false,
            rules: [{ required: true, message: 'Please input etrade desk phone!' }],
        },
        {
            title: 'Logo',
            dataIndex: 'etradeDeskImage',
            key: 'etradeDeskImage',
            update:true,
            show:false,
            // @ts-ignore
            renderInput :() => (
                <Upload
                    listType="picture-card"
                    beforeUpload={( file) => {
                        return false;
                    }}
                    accept={".png, .jpg, .jpeg"}
                    onRemove={() => setEtradeDeskImage(null)}
                    fileList={file4List}
                    onPreview={(e) => handlePreview(e, setPreview)}
                    onChange={(e) => handleFileListChange(setFile4List, setEtradeDeskImage, e)}
                >
                    {file4List.length === 0 && uploadButton}
                </Upload>
            )
        },
        {
            title: 'Member Name',
            dataIndex: 'membername',
            key: 'membername',
            update:true,
            show:false,
            rules: [{ required: true, message: 'Please input etrade desk member name!' }],
        },

        {
            title: 'Member Email',
            dataIndex: 'memberemail',
            key: 'memberemail',
            update:true,
            show:false,
            rules: [{ required: true, message: 'Please input etrade desk member email!' }],
        },

        {
            title: 'Member Rol',
            dataIndex: 'memberrol',
            key: 'memberrol',
            update:true,
            show:false,
            rules: [{ required: true, message: 'Please input etrade desk member rol!' }],
        },
        {
            title: 'Member Image',
            dataIndex: 'memberimage',
            key: 'memberimage',
            update:true,
            show:false,
            // @ts-ignore
            renderInput :() => (
                <Upload
                    listType="picture-card"
                    beforeUpload={( file) => {
                        return false;
                    }}
                    accept={".png, .jpg, .jpeg"}
                    onRemove={() => setMemberImage(null)}
                    fileList={fileList}
                    onPreview={(e) => handlePreview(e, setPreview)}
                    onChange={(e) => handleFileListChange(setFileList, setMemberImage, e)}
                >
                    {fileList.length === 0 && uploadButton}
                </Upload>
            )
        },

        {
            title: 'Member 2 Name',
            dataIndex: 'membername2',
            key: 'membername2',
            update:true,
            show:false,
        },

        {
            title: 'Member 2 Email',
            dataIndex: 'memberemail2',
            key: 'memberemail2',
            update:true,
            show:false,
        },

        {
            title: 'Member 2 Rol',
            dataIndex: 'memberrol2',
            key: 'memberrol2',
            update:true,
            show:false,
        },

        {
            title: 'Member 2 Image',
            dataIndex: 'memberimage2',
            key: 'memberimage2',
            update:true,
            show:false,
            // @ts-ignore
            renderInput :() => (
                <Upload
                    listType="picture-card"
                    beforeUpload={( file) => {
                        return false;
                    }}
                    accept={".png, .jpg, .jpeg"}
                    onRemove={() => setMember2Image(null)}
                    fileList={file2List}
                    onPreview={(e) => handlePreview(e, setPreview)}
                    onChange={(e) => handleFileListChange(setFile2List, setMember2Image, e)}
                >
                    {file2List.length === 0 && uploadButton}
                </Upload>
            )
        },

        {
            title: 'Actions',
            key: 'actions',
            show: true,
            update:false,
            render: (value, record) => {
                return (
                    <Space size="middle">
                        <a onClick={() => onUpdate(record)}>Update</a>
                        <Popconfirm
                            placement="left"
                            title="Delete etrade desk"
                            description={"Are you sure delete this etrade desk?"}
                            // @ts-ignore
                            onConfirm={() => onDelete(record)}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="link">Delete</Button>
                        </Popconfirm>
                    </Space>
                );
            },
        }

    ];

    const onDelete = (entity: IEtradeDeskUpdate) =>{
        // @ts-ignore
        const entityToDeleteId:number = entity.etradedeskid;

        const url = rootServiceURL() + `minimalgraph/rest/etradedesk/${entityToDeleteId}`;

        axios.delete(url, {headers: {token: "thisIsAValidTokenButDontUseIt"}}).then(response => {
            if(response.status == 204){
                loadEtradeDesks();
                openNotification(api,'success', 'top', `Etrade desk deleted`)

            }
            else{
                openNotification(api,'error', 'top', `An error has occurred deleting Etrade desk`)
            }
        }).catch(reason => {
            openNotification(api,'error', 'top', `An error has occurred deleting Etrade desk `+(reason.response ? reason.response.data : reason));
        });
    }

    const onUpdate = (entity: IEtradeDeskUpdate) => {
        clearFormData();
        // @ts-ignore

        console.log("on update .. ",entity)

        const updatedFieldValues: { [key: string]: any } = {};

        fields.forEach(field => {
            if(field.foreignkey){
                // @ts-ignore
                if(entity[field.key]){
                    // @ts-ignore
                    updatedFieldValues[field.key] = entity[field.key][field.key];
                    // @ts-ignore
                    formUpdate.setFieldsValue({ [field.key]: entity[field.key][field.key] });
                }
            }
            else{
                // @ts-ignore
                updatedFieldValues[field.key] = entity[field.key];
                // @ts-ignore
                formUpdate.setFieldsValue({ [field.key]: entity[field.key] });
            }

        });

        updatedFieldValues.etradedeskid = entity.etradedeskid;
        updatedFieldValues.memberid = entity.memberid;
        updatedFieldValues.memberid2 = entity.memberid2;

        if(entity.etradeDeskImage){
            const etradeDeskImage = {
                thumbUrl : "data:image/png;base64,"+ entity.etradeDeskImage,
                name: entity.etradedeskid+"_stadium.svg",
                type: "image/png",
                uid: '-1',
                status: "done"
            };
            setFile4List([etradeDeskImage])
        }

        if(entity.memberimage){
            const memberimage = {
                thumbUrl : "data:image/png;base64,"+ entity.memberimage,
                name: entity.etradedeskid+"_member.svg",
                type: "image/png",
                uid: '-1',
                status: "done"
            };
            setFileList([memberimage])
        }

        if(entity.memberimage2){
            const memberimage2 = {
                thumbUrl : "data:image/png;base64,"+ entity.memberimage2,
                name: entity.etradedeskid+"_member2.svg",
                type: "image/png",
                uid: '-1',
                status: "done"
            };
            setFile2List([memberimage2])
        }


        // @ts-ignore
        setUpdateFieldValues(updatedFieldValues);
        setIsModalUpdateOpen(true);
    }

    const onFinish = (values: IEtradeDeskUpdate) => {
        console.log("On finish ..",values)

        const entityToPost =  {...updateFieldValues, ...values,
            etradeDeskImage: etradeDeskImage ? base64FromArrayBuffer(etradeDeskImage) : null,
            memberimage: memberImage ? base64FromArrayBuffer(memberImage) : null,
            memberimage2: member2Image ? base64FromArrayBuffer(member2Image) : null,
            countryid: {countryid: values.countryid}
        };

        console.log("On finish entityToPost..",entityToPost)

        console.log("On finish updateFieldValues..",updateFieldValues)
        //@ts-ignore
        delete entityToPost.actions;

        const url = rootServiceURL() + `completegraph/etradedesk/updateEtradeDesk`

        axios.post(url, entityToPost, {headers: {token: getUserToken()}}).then(response => {
            if(response.status == 200){
                openNotification(api,'success', 'top', "Etrade desk update successfully")
                clearFormData();
                setIsModalUpdateOpen(false);
                loadEtradeDesks();
            }
            else{
                openNotification(api,'error', 'top', "An error has occurred loading etrade desks")
            }
        }).catch(reason => {
            openNotification(api,'error', 'top', "An error has occurred loading etrade desks "+(reason.response ? reason.response.data : reason));
        });


    }

    const clearFormData = () => {
        formUpdate.resetFields();
        setFile4List([]);
        setFile2List([]);
        setFileList([]);
        setEtradeDeskImage(null);
        setMemberImage(null);
        setMember2Image(null);
    }

    const deleteMember2 = () =>{
        if(updateFieldValues && updateFieldValues.memberid2){
            const url = rootServiceURL() + `completegraph/etradedesk/deleteMember/`+updateFieldValues.memberid2;

            axios.get(url, {headers: {token: getUserToken()}}).then(response => {
                if(response.status == 200){
                    openNotification(api,'success', 'top', "Member 2 deleted successfully")
                    clearFormData();
                    setIsModalUpdateOpen(false);
                    loadEtradeDesks();
                }
                else{
                    openNotification(api,'error', 'top', "An error has occurred deleting member 2")
                }
            }).catch(reason => {
                openNotification(api,'error', 'top', "An error has occurred deleting member 2 "+(reason.response ? reason.response.data : reason));
            });
        }
        else{
            message.warning("Member2 is undefined")
        }
    }

    return (
        <>
            {contextHolder}
            <Row>
                {preview?.previewOpen &&
                    <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                            visible: preview?.previewOpen,
                            onVisibleChange: (visible) => setPreview(null),
                            afterOpenChange: (visible) => !visible && setPreview(null),
                        }}
                        src={preview?.previewImage}
                    />
                }
                <Col lg={4} xl={4} xxl={4} xs={0} sm={0} md={0} >
                    <AdminMenu selectedKey={"etradedesk"}></AdminMenu>
                </Col>
                <Col lg={20} xl={20} xxl={20} xs={0} sm={0} md={0} style={{padding: 20}}>
                    <Row style={{ alignItems: 'start', justifyContent: 'start', width: '100%', paddingBottom: 30}}>
                        <Col xs={{offset:0, span:23}} sm={{offset:0, span:23}} md={8} lg={8} xl={8} xxl={8}>
                            <h2>Etrade Desks</h2>
                        </Col>
                        <Col xs={{offset:0, span:23}} sm={{offset:0, span:23}} md={8} lg={8} xl={8} xxl={8}>

                        </Col>
                        <Col xs={{offset:0, span:23}} sm={{offset:0, span:23}} md={8} lg={8} xl={8} xxl={8}  style={{alignItems: 'end', justifyContent: 'end',  display: "flex" }} >
                            <Button type="primary" onClick={()=> logOut(history)}>LOG OUT</Button>
                        </Col>
                    </Row>
                    <Modal
                        title={"Update Etrade Desk"}
                        open={isModalUpdateOpen}
                        footer={null}
                        onCancel={() => {
                            clearFormData();
                            setIsModalUpdateOpen(false);
                        }}>
                        <Form
                            name="basic"
                            form={formUpdate}
                            initialValues={{ remember: false }}
                            onFinish={(values) => onFinish(values)}
                            scrollToFirstError
                            autoComplete="off">

                            {fields?.filter(field =>(field.update)).map(field => (
                                <Form.Item
                                    labelCol={{ span: 24 }}
                                    label={""+field.title}
                                    name={field.key}
                                    rules={field.rules}>
                                    {field.renderInput ? field.renderInput() : <Input className={styles.textInput} />}
                                </Form.Item>
                            ))}

                            {updateFieldValues && updateFieldValues.memberid2 &&
                                <Popconfirm
                                    placement="left"
                                    title="Delete member 2"
                                    description={"Are you sure delete member 2"}
                                    // @ts-ignore
                                    onConfirm={deleteMember2}
                                    onCancel={() => {}}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger={true} style={{marginRight: 15}}>
                                        Delete member 2
                                    </Button>
                                </Popconfirm>
                                }
                            <Button type="primary" htmlType="submit">
                                Update Etrade Desk
                            </Button>
                        </Form>
                    </Modal>
                    <PhocusTable columns={fields.filter(f => f.show)} dataSource={etradeDesks} />
                </Col>

            </Row>
        </>
    );
}
export default EtradeDesk;