import React, { useState, useEffect } from 'react';
import {
    Button,
    Checkbox,
    message,
    Col,
    Form,
    Input,
    Row,
    Select,
    Upload,
    Image,
    Typography,
    InputNumber,
    UploadFile, notification, Tooltip, Modal
} from 'antd';

import {PlusOutlined, QuestionCircleOutlined, UploadOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import Link from 'antd/es/typography/Link';
import {RcFile, UploadChangeParam} from "antd/es/upload";
import axios from "axios";
import {openNotification} from "../../util/OpenNotification";
import {rootServiceURL} from "../../config";
import PaypalComponent from "./PaypalComponent";
import {useHistory} from "react-router-dom";
import {ICountry} from "../../interfaces/Country";
import {ICertificateType} from "../../interfaces/CertificateType";
import {ICompany} from "../../interfaces/Company";
import {checkLogged, companyProfilePhotoURL, logOut} from "../../util/Utils";
import styles from './CompleteData.module.css'

function CompleteData() {
    const [api, contextHolder] = notification.useNotification();
    const [countries, setCountries] = useState<ICountry[]>([]);
    const [certificateTypes, setCertificateTypes] = useState<ICertificateType[]>([]);

    // @ts-ignore
    const [companyData, setCompanyData] = useState<ICompany>(null);
    const [myOrder, setMyOrder] = useState(undefined);
    const history = useHistory();
    const [form] = Form.useForm();

    const [companyImage, setCompanyImage] = useState<Uint8Array|null>(null);
    const [fileList, setFileList] = useState<any[]>([]);
    const [preview, setPreview] = useState<{
        previewOpen: boolean,
        previewImage: string | undefined,
        previewTitle: string | undefined
    } | null>(null);

    function saveCompanyData(values: any, order: { id: any; }) {
        const entityToPut = {
            ...companyData,
            ...values,
            categoryid: companyData.categoryid,
            certificatetypeid: {certificatetypeid: values.certificatetypeid},
            companyverified: false,
            completedata: true,
            paymentid: order.id,
            countryid: {countryid: values.countryid},
        }

        delete entityToPut.checkbox;
        delete entityToPut.checkbox2;
        delete entityToPut.logo;

        console.log("Put data ", entityToPut)
        const url = rootServiceURL() + "completegraph/rest/Company"
        axios.put(url, entityToPut, {headers: {token: "thisIsAValidTokenButDontUseIt"}})
            .then(value => {
                console.log("Value", value)
                message.success("Payment complete and Company data saved succesfully");
                // openNotification(api,'success', 'top', "Payment complete and Company data saved succesfully");
                history.push("/mycompany")
            }).catch(reason => {
            console.error("Reason", reason)
            openNotification(api, 'error', 'top', "Payment complete but An error has occurred saving company data " + (reason.response ? reason.response.data : reason));
        });
    }

    const onFinish = (values: any) => {
        console.log("values", values)

        if(!companyImage){
            message.error("You must select a logo")
            return;
        }

        if (myOrder!=undefined){
            // @ts-ignore
            myOrder.capture().then((order)=>{
                console.log("paypal order", order)
                let status: string = order.status;
                if (status=='COMPLETED'){
                    saveCompanyData(values, order);
                }else{
                    console.error("paypal response: ",order)
                    openNotification(api,'error', 'top', "An error has occurred paying, try it again later");
                }
            }).catch((reason :any) => {
                console.error("Error paypal ",reason)
            });
        }
        else if(myOrder === undefined && companyData.paymentid){
            saveCompanyData(values, {id: companyData.paymentid});
        }
        else{
            openNotification(api,'error', 'top', "You must complete the payment before submit")
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        checkLogged(history);
        loadCompanyData();
        loadCountries();
        loadCertificateTypes();
        return () => {
        };
    }, []);

    const handleSubmitClicked = (order: any) => {
        setMyOrder(order);
        if(order){
            openNotification(api,'success', 'top', "Payment authorized, please click submit to make payment and complete registration");
        }
    };

    const fileToBytes = async (file: UploadFile): Promise<Uint8Array> => {
        return new Promise<Uint8Array>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const result = event.target?.result as ArrayBuffer;
                const bytes = new Uint8Array(result);
                resolve(bytes);
            };
            reader.onerror = (error) => reject(error);
            // @ts-ignore
            reader.readAsArrayBuffer(file);
        });
    };
    const handleFileChange = async (info: UploadChangeParam) => {
        setFileList(info.fileList);

        if(info.fileList.length > 0){
            fileToBytes(info.file).then(value => {
                if(value && companyData && companyData.companyid){
                    const url = companyProfilePhotoURL(companyData);
                    axios.post(url!, value, {
                        headers: {
                            token: "thisIsAValidTokenButDontUseIt",
                            'Content-Type': "application/octet-stream",
                        }
                    }).then(r => {
                        setFileList(info.fileList);
                        setCompanyImage(value);
                        openNotification(api,'success', 'top', "Profile image saved succesfully");
                    }).catch(reason => {
                        openNotification(api,'error', 'top', "An error has occurred saving profile image "+(reason.response ? reason.response.data : reason));
                    })
                }
            }).catch(reason => {
                openNotification(api,'error', 'top', "An error has occurred loading profile image "+(reason.response ? reason.response.data : reason));
            })
        }
    };

    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    const handlePreview = async (file: UploadFile) => {
        if(file.uid === '-1'){
            file.preview = file.thumbUrl;
        }
        else{
            if (!file.url && !file.preview) {
                // @ts-ignore
                file.preview = await getBase64(file.originFileObj!);
            }
        }

        setPreview({
            previewOpen: true,
            previewImage: file.url || file.preview,
            previewTitle: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
        })
    };

    const loadCompanyData = () => {
        const userData = localStorage.getItem('userData');

        if(userData != null){
            const url = rootServiceURL() + "fullgraph/rest/search/Company"
            const criteriaItems = {
                "criteriaItems":[
                    {
                        fieldName: "email",
                        comparisonOperator: "OPERATOR_EQUALS",
                        fieldValue: JSON.parse(userData).phocusUser.email
                    }
                ]
            }

            axios.post(url, criteriaItems, {headers: {token: "thisIsAValidTokenButDontUseIt"}}).then(response => {
                if(response.status == 200){
                    if(response.data.length > 0){
                        setCompanyData(response.data[0]);
                        const company = response.data[0];

                        if(company.completedata){
                            history.push("/mycompany")
                        }

                        form.setFieldsValue({...company,
                            countryid: company.countryid?.countryid,
                            certificatetypeid: company.certificatetypeid?.certificatetypeid,
                        });
                    }
                    else{
                        openNotification(api,'error', 'top', "An error has occurred loading company data")
                    }
                }
                else{
                    openNotification(api,'error', 'top', "An error has occurred loading company data")
                }
            }).catch(reason => {
                openNotification(api,'error', 'top', "An error has occurred loading company data  "+(reason.response ? reason.response.data : reason));
            });
        }
    }

    const loadCountries = () => {
        const url = rootServiceURL() + "minimalgraph/rest/search/Country"
        const criteriaItems = {
            "criteriaItems":[]
        }
        axios.post(url, criteriaItems, {headers: {token: "thisIsAValidTokenButDontUseIt"}}).then(response => {
            if(response.status == 200){
                setCountries(response.data);
            }
            else{
                openNotification(api,'error', 'top', "An error has occurred loading countries")
            }
        }).catch(reason => {
            openNotification(api,'error', 'top', "An error has occurred loading countries "+(reason.response ? reason.response.data : reason));
        });
    }
    const loadCertificateTypes = () => {
        const url = rootServiceURL() + "minimalgraph/rest/search/Certificatetype"
        const criteriaItems = {
            "criteriaItems":[]
        }
        axios.post(url, criteriaItems, {headers: {token: "thisIsAValidTokenButDontUseIt"}}).then(response => {
            if(response.status == 200){
                setCertificateTypes(response.data);
            }
            else{
                openNotification(api,'error', 'top', "An error has occurred loading certificate types")
            }
        }).catch(reason => {
            openNotification(api,'error', 'top', "An error has occurred loading certificate types "+(reason.response ? reason.response.data : reason));
        });
    }


    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload Image
            </div>
        </div>
    );

    // @ts-ignore
    return (
        <>
            {contextHolder}
            {preview?.previewOpen &&
                <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: preview?.previewOpen,
                        onVisibleChange: (visible) => setPreview(null),
                        afterOpenChange: (visible) => !visible && setPreview(null),
                    }}
                    src={preview?.previewImage}
                />
            }

            <Row style={{ alignItems: 'start', justifyContent: 'start', width: '100%', marginTop:80 }}>
                <Col xs={{offset:1, span:23}} sm={{offset:1, span:23}} md={8} lg={8} xl={8} xxl={8}>

                    <img width={'200px'} src={"/e-trade-title.png"} />
                </Col>
                <Col xs={{offset:1, span:23}} sm={{offset:1, span:23}} md={8} lg={8} xl={8} xxl={8}>
                    <h1 style={{ color: '#003e86'}}>MY COMPANY</h1>
                </Col>
                <Col xs={{offset:1, span:23}} sm={{offset:1, span:23}} md={8} lg={8} xl={4} xxl={4}  style={{alignItems: 'end', justifyContent: 'end',}} >
                    <Button type="primary" onClick={()=> logOut(history)}>LOG OUT</Button>
                </Col>
            </Row>
            <div style={{borderTop: '3px outset #d4d9d6', margin: 20}}/>
            <Row  justify="center">
                <Col span={17}>
                    <h1 style={{ color: '#003e86'}}>Register</h1>
                </Col>
            </Row>
            <Row justify="center">
                <Col span={17}>
                    <p>Complete the following data:</p>
                </Col>
            </Row>
            <Form
                name="basic"
                form={form}
                initialValues={{ remember: true }}
                className={styles.fullWidth}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
                autoComplete="off">
                <Row justify="center" className={styles.formMargin}>
                    <Col lg={8} xl={8} xxl={8} xs={20} sm={20} md={20}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label="First Name"
                            name="registereduserfirstname"
                            initialValue={companyData?.registereduserfirstname}
                            rules={[{ required: true, message: 'Please input your First Name!' }]}>
                            <Input  className={styles.defaultInput} maxLength={220}/>
                        </Form.Item>
                    </Col>
                    <Col lg={{span:8, offset:1}} xl={{span:8, offset:1}} xxl={{span:8, offset:1}} xs={20} sm={20} md={20}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label="Last Name"
                            name="registereduserlastname"
                            rules={[{ required: true, message: 'Please input your Last Name!' }]}>
                            <Input  className={styles.defaultInput} maxLength={220}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center" className={styles.spaceBetweenRows}>
                    <Col lg={8} xl={8} xxl={8} xs={20} sm={20} md={20}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={
                                <span>
                                    Organization{' '}
                                    <Tooltip title="Organization name or comercial brand">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                            name="company"
                            rules={[{ required: true, message: 'Please input your Organization!' }]}>
                            <Input  className={styles.defaultInput} maxLength={220}/>
                        </Form.Item>
                    </Col>
                    <Col lg={{span:8, offset:1}} xl={{span:8, offset:1}} xxl={{span:8, offset:1}} xs={20} sm={20} md={20}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={
                                <span>
                                    Oficial Registration{' '}
                                    <Tooltip title="Oficial registration of company">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }

                            name="oficialregistration"
                            rules={[{ required: true, message: 'Please input your Oficial Registration!' }]}>
                            <Input  className={styles.defaultInput} maxLength={220}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center" className={styles.spaceBetweenRows}>
                    {/*<Col lg={8} xl={8} xxl={8} xs={20} sm={20} md={20}>*/}
                    {/*    <Form.Item*/}
                    {/*        labelCol={{ span: 24 }}*/}
                    {/*        label="Category"*/}
                    {/*        name="categoryid"*/}
                    {/*        rules={[{ required: true, message: 'Please input your Category!' }]}>*/}
                    {/*        <Select*/}
                    {/*            className={styles.select}*/}
                    {/*            options={*/}
                    {/*                categories.map(category => ({*/}
                    {/*                        value : category.categoryid,*/}
                    {/*                        label: category.category*/}
                    {/*                    }*/}
                    {/*                ))*/}
                    {/*            }/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    <Col lg={8} xl={8} xxl={8} xs={20} sm={20} md={20}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label="Certificate Type"
                            name="certificatetypeid"
                            rules={[{ required: true, message: 'Please input your Certificate Type!' }]}>
                            <Select
                                className={styles.select}
                                options={
                                    certificateTypes.map(certificateType => ({
                                            value : certificateType.certificatetypeid,
                                            label: certificateType.certificatetype
                                        }
                                    ))
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={{span:8, offset:1}} xl={{span:8, offset:1}} xxl={{span:8, offset:1}} xs={20} sm={20} md={20}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={
                                <span>
                                    Tax{' '}
                                    <Tooltip title="VAT number / Tax number">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                            name="tax"
                            rules={[{ required: true, message: 'Please input your Tax!' }]}>
                            <Input  className={styles.defaultInput} maxLength={220}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center" className={styles.spaceBetweenRows}>
                    <Col lg={8} xl={8} xxl={8} xs={20} sm={20} md={20}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label="Address"
                            name="address"
                            rules={[{ required: true, message: 'Please input your Address!' }]}>
                            <Input  className={styles.defaultInput} maxLength={220}/>
                        </Form.Item>
                    </Col>
                    <Col lg={{span:8, offset:1}} xl={{span:8, offset:1}} xxl={{span:8, offset:1}} xs={20} sm={20} md={20}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label="Country"
                            name="countryid"
                            rules={[{ required: true, message: 'Please input your Country!' }]}>
                            <Select
                                className={styles.select}
                                options={
                                    countries.map(country => ({
                                            value : country.countryid,
                                            label: country.country
                                        }
                                    ))
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center" className={styles.spaceBetweenRows}>
                    <Col lg={8} xl={8} xxl={8} xs={20} sm={20} md={20}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label="Phone Number"
                            name="phone"
                            rules={[{ required: true, message: 'Please input your Phone Number!' }]}>

                            <Input  className={styles.defaultInput}/>
                        </Form.Item>
                    </Col>
                    <Col lg={{span:8, offset:1}} xl={{span:8, offset:1}} xxl={{span:8, offset:1}} xs={20} sm={20} md={20}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={
                                <span>
                                       Established year{' '}
                                    <Tooltip title="Year of oficial constitution">
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </span>
                            }

                            name="establishedyear"
                            rules={[{type: 'number', required: true, message: 'Please input your Established year!' }]}>
                            <InputNumber className={styles.inputNumber}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center" className={styles.spaceBetweenRows}>
                    <Col lg={8} xl={8} xxl={8} xs={20} sm={20} md={20}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label="Website"
                            name="website"
                            rules={[{ required: true, message: 'Please input your Website!' }]}>
                            <Input  className={styles.defaultInput} maxLength={200}/>
                        </Form.Item>
                    </Col>
                    <Col lg={{span:8, offset:1}} xl={{span:8, offset:1}} xxl={{span:8, offset:1}} xs={20} sm={20} md={20}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label="Email"
                            name="email"
                            rules={[{type: 'email', required: true, message: 'Please input your Email!' }]}>
                            <Input disabled className={styles.defaultInput}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center" className={styles.spaceBetweenRows}>

                    {/*<Col lg={{span:8, offset:1}} xl={{span:8, offset:1}} xxl={{span:8, offset:1}} xs={20} sm={20} md={20}>*/}
                    {/*    <Form.Item*/}
                    {/*        labelCol={{ span: 24 }}*/}
                    {/*        label="Domain Control"*/}
                    {/*        name="domaincontrol"*/}
                    {/*        rules={[{required: true, message: 'Please input your Domain Control!' }]}>*/}
                    {/*        <Input  className={styles.defaultInput}/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                </Row>
                <Row justify="center" className={styles.spaceBetweenRows}>
                    <Col lg={17} xl={17} xxl={17} xs={20} sm={20} md={20}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={
                                <span>
                                       Main Activity{' '}
                                    <Tooltip title="Resume of main activities of the company">
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </span>
                            }

                            name="mainactivity"
                            rules={[{required: true, message: 'Please input your Main Activity!' }]}>
                            <Input className={styles.defaultInput} maxLength={254}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center" className={styles.spaceBetweenRows}>
                    <Col lg={17} xl={17} xxl={17} xs={20} sm={20} md={20}>
                        <Row >
                            <Form.Item
                                labelCol={{ span: 24 }}
                                label="Company Logo"
                                name="logo">
                                <Upload
                                    listType="picture-card"
                                    beforeUpload={( file) => {
                                        return false;
                                    }}
                                    accept={".png, .jpg, .jpeg"}
                                    onRemove={() => setCompanyImage(null)}
                                    onChange={handleFileChange}
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                >
                                    {fileList.length === 0 && uploadButton}
                                </Upload>
                            </Form.Item>
                        </Row>
                    </Col>
                </Row>

                <Row justify="center" className={styles.spaceBetweenRows}>
                    <Col lg={17} xl={17} xxl={17} xs={20} sm={20} md={20}>
                        <Typography.Title level={4} className={styles.terms}>Terms and Conditions</Typography.Title>
                        <Form.Item label="" name="checkbox" valuePropName="checked" rules={[{required:true, message: 'Please accept!'}]}>
                            <Checkbox>I agree that I have read, understood and agree to the Terms and Conditions</Checkbox>
                        </Form.Item>
                        <Form.Item label="" name="checkbox2" valuePropName="checked" rules={[{required:true, message: 'Please accept!'}]}>
                            <Checkbox>I agree that I have read, understood and agree to the Privacy Policy</Checkbox>
                        </Form.Item>
                        For more information, <Link>click here</Link>. For any change or modification of your data, please use this e-mail address:  <Link>datos@etradeworld.com</Link>
                    </Col>
                </Row>

                {!companyData?.paymentid &&
                    <>
                        <Row justify="center" className={styles.spaceBetweenRows}>
                            <Col lg={3} xl={3} xxl={3} xs={20} sm={20} md={20}>
                                <p className={classNames("poppinsBold",styles.payText)}>Pay my E-Trade ID</p>
                                <p className={classNames("poppinsBold",styles.chfText)}>65 <span className={classNames("poppinsLight",styles.yearText)}>CHF/year</span></p>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col lg={17} xl={17} xxl={17} xs={20} sm={20} md={20}>
                                <p style={{textAlign: "center"}} className={classNames("programText")}><span style={{color: "red"}}>**</span>Depending on the country and the entity involved, it may apply an additional local verification cost.</p>
                            </Col>
                        </Row>
                        <Row justify="center" className={styles.spaceBetweenRows}>
                            <Col lg={3} xl={3} xxl={3} xs={20} sm={20} md={20} style={myOrder ? {display: "none"} : {}}>
                                <PaypalComponent handleSubmitClicked={handleSubmitClicked}/>
                            </Col>
                        </Row>
                        {myOrder &&
                            <Row justify="center" className={styles.spaceBetweenRows}>
                                <Col lg={17} xl={17} xxl={17} xs={20} sm={20} md={20}>
                                    <p style={{color: "red"}}>Payment authorized, please click submit to make payment and complete registration.</p>
                                </Col>
                            </Row>
                        }
                    </>
                }

                <Row justify="center" className={styles.fortyTopMargin}>
                    <Col lg={20} xl={5} xxl={5} xs={20} sm={20} md={20}>
                        <Form.Item >
                            {/*disabled={!myOrder}*/}
                            <Button disabled={!myOrder && !companyData?.paymentid} className={styles.submitButton} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="center" className={styles.doubleSpaceBetweenRowsTopAndBottom} >
                    <span className={styles.programBorder}>
                        <Col lg={6} xl={6} xxl={6} xs={20} sm={20} md={20}>
                            <img width={'200px'} src={"/e-credentials.svg"}/>
                        </Col>
                        <Col lg={17} xl={17} xxl={17} xs={20} sm={20} md={20}>
                            <p className={classNames("programText")}>The e-Credentials program collects and validates information, background and references that
                                companies add to their e-trade ID to enhance their credibility.</p>
                            <p className={classNames("programText")}>Access certifications, documents, and testimonials from clients of this company.</p>
                            <p className={classNames("programText")}>Soon available for those users who have an e-trade ID certificate.</p>
                        </Col>
                    </span>
                </Row>
            </Form>
        </>
    );

}
export default CompleteData;