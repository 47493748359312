import {PlusOutlined} from "@ant-design/icons";
import React from "react";
import {RcFile} from "antd/es/upload";
import {fileToBytes} from "../util/Utils";
import {message, UploadFile} from "antd";

export const uploadButton = (
    <div>
        <PlusOutlined />
        <div
            style={{
                marginTop: 8,
            }}
        >
            Upload Image
        </div>
    </div>
);


const  handleFileChange =  (info: any, setValue: Function) => {
    fileToBytes(info).then((value: any) => {
        if(value){
            message.success("Image set sucessfully")
            setValue(value);
        }
    }).catch((reason: { response: { data: any; }; }) => {
        message.error("An error has occurred loading profile image "+(reason.response ? reason.response.data : reason));
    })
};
export const handleFileListChange = (setValueList: Function, setValue: Function, {fileList: newFileList}: any) => {
    setValueList(newFileList);

    if(newFileList.length > 0){
        handleFileChange(newFileList[0].originFileObj, setValue);
    }
}

export const getBase64 = (file: RcFile) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const handlePreview = async (file: UploadFile, setPreview: Function) => {
    if(file.uid === '-1'){
        file.preview = file.thumbUrl;
    }
    else{
        if (!file.url && !file.preview) {
            // @ts-ignore
            file.preview = await getBase64(file.originFileObj!);
        }
    }

    setPreview({
        previewOpen: true,
        previewImage: file.url || file.preview,
        previewTitle: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    })
};