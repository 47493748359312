import React from 'react';
import {ICertificateType} from "../../../interfaces/CertificateType";
import {IField} from "../../../interfaces/IField";
import AdminCrud from "../../../component/admincrud/AdminCrud";
export default function CeriticateTypes() {
    const fields: IField<ICertificateType>[] = [
        {
            title: 'Ceritificate type',
            dataIndex: 'certificatetype',
            key: 'certificatetype',
            show:true,
            update:true,
            sorter: (a, b) => a.certificatetype > b.certificatetype ? 1 : -1,
            rules: [{ required: true, message: 'Please input your Certificate Type!' }],
        },
    ];
    return (
        <AdminCrud<ICertificateType> entity="certificatetype" columnsToAdd={fields} menu={'certificatetypes'} />
    );
}