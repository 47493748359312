import {NotificationInstance, NotificationPlacement} from "antd/es/notification/interface";

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const openNotification = (api: NotificationInstance, type: NotificationType, placement: NotificationPlacement, message: string) => {
    api[type]({
        message: type,
        description: message,
        placement,
    });
};

export const openNotificationWithTitle = (api: NotificationInstance, type: NotificationType, placement: NotificationPlacement, title: string, message: string) => {
    api[type]({
        message: title,
        description: message,
        placement,
    });
};