import {rootServiceURL} from "../../../config";
import axios from "axios";
import {openNotification} from "../../../util/OpenNotification";
import { NotificationInstance } from "antd/es/notification/interface";
import {getUserToken} from "../../../util/Utils";

export const verifyCompany = (companyId: number, api: NotificationInstance, callback: Function) => {
    const url = rootServiceURL() + "minimalgraph/company/verify/"+companyId;
    try {
        axios.get(url ,{ headers: { token: getUserToken() } }).then(value => {
            console.log("value", value)
            if(value.status == 200){
                openNotification(api, 'success', 'top', 'Company verified successfully');
                callback && callback();
            }
            else{
                openNotification(api, 'error', 'top', 'Company verify error: '+value.data.message);
            }

        }).catch(reason => {
            console.log("reason", reason)
            openNotification(api, 'error', 'top', 'Company verify error: '+(reason.response ? reason.response.data : reason));
        });
    } catch (error) {
        console.error('Error:', error);
    }
};
