import React, {useEffect,  useState} from 'react';
import {Button, Col, message, notification, Row} from "antd";
import styles from './companycertificate.module.css';
import '../../component/commonstyles/CommonStyle.css';
import {CheckOutlined, FileTextOutlined} from "@ant-design/icons";
import {rootServiceURL} from "../../config";
import axios from "axios";
import {openNotification} from "../../util/OpenNotification";
import {RouteComponentProps} from "react-router-dom";
import classNames from "classnames";
import {ICompany} from "../../interfaces/Company";
import {frontServer} from "../../config/Settings";
import moment from "moment/moment";
import dayjs from "dayjs";
import {downloadTicketPdf} from "../../util/Utils";
export interface CompanyCertificateProps extends RouteComponentProps<{ companyId: string, pdf?: string }> {}

export default function CompanyCertificate(props: CompanyCertificateProps) {

    const [companyData, setCompanyData] = useState<ICompany | null>(null);
    const [api, contextHolder] = notification.useNotification();
    const loadCompanyData = () => {
        const companyId = props.match.params.companyId;

        if(companyId != null){
            let url = rootServiceURL() + "fullgraph/company/getCompanyById/" + companyId;
            axios.get(url, {headers: {token: "thisIsAValidTokenButDontUseIt"}}).then(response => {
                if(response.status == 200){
                    if(response.data){
                        setCompanyData(response.data);
                    }
                    else{
                        openNotification(api,'error', 'top', "An error has occurred loading company data")
                    }
                }
                else{
                    openNotification(api,'error', 'top', "An error has occurred loading company data")
                }
            }).catch(reason => {
                openNotification(api,'error', 'top', "An error has occurred loading company data "+(reason.response ? reason.response.data : reason));
            });
        }

    }
    useEffect(() => {
        loadCompanyData();
    }, []);
    return (
        <>
            {contextHolder}
            <div className={styles.mainDiv}>
                <Row justify="start" >
                    <Col xs={24} sm={5} md={5} lg={{span:5, offset:1}} className={styles.colMarginTen}>
                        <img className={styles.eTradeTitleImg} src={'/e-trade-ID-verified.jpeg'} alt="Logo" />
                    </Col>
                    <Col xs={24} sm={{span:5, offset:12}} md={{span:5, offset:13}} lg={{span:4, offset:14}} className={styles.colMarginTen}>
                        <img className={styles.eTradeTitleImg} src={'/e-trade-world-federation.png'} alt="Logo" />
                    </Col>

                </Row>
                <Row justify="center" style={{marginTop:20}} className={styles.certificateSpace}>
                    <Col lg={22} xs={{span: 24}} md={{span: 24}} sm={{span: 24}} className={styles.zeroMarginBottom}>
                        <p className={styles.certificateText}>
                            <span className={classNames("poppinsBold",styles.securedCertificate)} >
                                This certificate is secured
                            </span>
                            <br/>
                            <span className={classNames("poppinsLight",styles.securedCertificateSecondary)} >
                                by WTPF Blockchain Secure Network
                            </span>
                            <br/>
                            {companyData?.companyverified &&
                                <Button icon={<FileTextOutlined />} size={"large"} className={styles.downloadButton}
                                        onClick={() => downloadTicketPdf(companyData?.companyid, api)}
                                >Download PDF</Button>
                            }
                        </p>
                        <p className={styles.separationP}>

                            <span className={classNames("robotoLight", styles.id)} >
                                ID Number:
                            </span>
                            <span style={{marginLeft:5}} className={classNames("robotoLight", styles.idNumber)} >
                                {companyData?.etradeid}
                            </span>

                        </p>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col className={styles.verifiedSeparation}>
                        <p className={classNames("poppinsBold",styles.verifiedText)}>
                            {companyData?.companyverified ? "Verified Details" : "Verify Pending"}
                        </p>
                    </Col>
                </Row>
                <Row justify="center" >
                    <Col lg={22} xs={{span: 24}} md={{span: 24}} sm={{span: 24}}
                         className={styles.greyColum}>
                        <Row className={styles.borderBetweenContent}  >
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Company Name</p>
                            </Col>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                {companyData && (
                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                        {companyData.company == null ? 'Empty' : companyData.company}
                                    </p>
                                )}
                            </Col>
                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center" className={styles.zeroMarginTop}>
                    <Col lg={22} xs={24} sm={24} md={24} className={styles.zeroMarginBottom}>
                        <Row className={styles.borderBetweenContent}>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Oficial
                                    Registration</p>
                            </Col>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                {companyData && (
                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                        {companyData.oficialregistration == null ? 'Empty' : companyData.oficialregistration}
                                    </p>
                                )}
                            </Col>
                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center" className={styles.zeroMarginTop}>
                    <Col lg={22} xs={24} sm={24} md={24}
                         className={classNames(styles.zeroMarginBottom, styles.colorRow, styles.greyColum)}>
                        <Row className={styles.borderBetweenContent}>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Tax Number</p>
                            </Col>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                {companyData && (
                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                        {companyData.tax == null ? 'Empty' : companyData.tax}
                                    </p>
                                )}
                            </Col>
                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center" className={styles.zeroMarginTop}>
                    <Col lg={22} xs={24} sm={24} md={24} className={styles.zeroMarginBottom}>
                        <Row className={styles.borderBetweenContent}>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Location</p>
                            </Col>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                {companyData && (
                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                        {companyData.address == null ? 'Empty' : companyData.address}
                                    </p>
                                )}
                            </Col>
                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center" className={styles.zeroMarginTop}>
                    <Col lg={22} xs={24} sm={24} md={24}
                         className={classNames(styles.zeroMarginBottom, styles.colorRow)}
                    >
                        <Row className={styles.borderBetweenContent}>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Main Activity</p>
                            </Col>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                {companyData && (
                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                        {companyData.mainactivity}
                                    </p>
                                )}
                            </Col>
                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center" className={styles.zeroMarginTop}>
                    <Col lg={22} xs={24} sm={24} md={24} className={classNames(styles.zeroMarginBottom)}>
                        <Row className={styles.borderBetweenContent}>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Website</p>
                            </Col>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                {companyData && (
                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                        <a href={`http://${companyData.website}`} target="_blank" rel="noopener noreferrer" style={{color:'black'}}>
                                            {companyData.website}
                                        </a>
                                    </p>
                                )}
                            </Col>
                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center" className={styles.zeroMarginTop}>
                    <Col lg={22} xs={24} sm={24} md={24} className={classNames(styles.zeroMarginBottom, styles.colorRow)}>

                        <Row className={styles.borderBetweenContent}>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Domain Control</p>
                            </Col>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                {companyData && (
                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                        {companyData.domaincontrol == null ? 'Empty' : companyData.domaincontrol}
                                    </p>
                                )}
                            </Col>
                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center" className={styles.zeroMarginTop}>
                    <Col lg={22} xs={24} sm={24} md={24} className={classNames(styles.zeroMarginBottom)}>
                        <Row className={styles.borderBetweenContent}>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Certificate
                                    Type</p>
                            </Col>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                {companyData && (
                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                        {companyData.certificatetypeid == null ? 'Empty' : companyData.certificatetypeid.certificatetype}
                                    </p>
                                )}
                            </Col>
                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center" className={styles.zeroMarginTop}>
                    <Col lg={22} xs={24} sm={24} md={24} className={classNames(styles.zeroMarginBottom, styles.colorRow)}>

                        <Row className={styles.borderBetweenContent}>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>ID issued</p>
                            </Col>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                    {companyData?.idissued ? moment(companyData?.idissued).format("DD-MM-YYYY") : ""}
                                </p>
                            </Col>
                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center" className={styles.zeroMarginTop}>
                    <Col lg={22} xs={24} sm={24} md={24} className={classNames(styles.zeroMarginBottom)}>
                        <Row className={styles.borderBetweenContent}>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>ID Expiration</p>
                            </Col>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                    {companyData?.idexpiration ? moment(companyData?.idexpiration).format("DD-MM-YYYY") : ""}
                                </p>
                            </Col>
                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center" className={styles.zeroMarginTop}>
                    <Col lg={22} xs={24} sm={24} md={24} className={classNames(styles.zeroMarginBottom, styles.colorRow)}>

                        <Row className={styles.borderBetweenContent}>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Blockchain hash</p>
                            </Col>
                            <Col offset={1} lg={8} xs={8} sm={8} md={8} >

                                <p style={{paddingBottom:10, overflowWrap: "anywhere"}} className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                    {companyData?.blockchainhash ? <a onClick={() => window.open("https://etherscan.io/tx/" + companyData?.blockchainhash, "_blank")}
                                        >{companyData?.pdfhash}</a>
                                        : <span>{companyData?.pdfhash}</span>}
                                </p>
                            </Col>
                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                       md={{span: 2, offset: 3}} className={styles.iconCol}>
                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {!props.match.params.pdf &&
                    <>
                    <Row justify="center" className={classNames(styles.centerFull)}>
                        <Col xxl={8} xl={8} lg={10} xs={20} sm={20} md={20} className={styles.tenMarginTop}>
                            <Button
                                onClick={() => window.open(frontServer + "mycompany/" + companyData!.companyid, "_blank")}
                                style={{background: '#07305b', width: '100%'}}
                                className={classNames("poppinsBold", styles.defaultButton)} type="primary">VIEW COMPANY PROFILE</Button>
                        </Col>
                    </Row>

                    {companyData && companyData.etradeDeskImage &&
                        <>
                            <Row justify="center" className={styles.zeroMarginTop}>
                                <Col span={12}>
                                    <Row justify="center" style={{marginTop:25}}>
                                        <Col offset={1} lg={22} xs={24} sm={24} md={24} style={{fontSize:18}} className={classNames(styles.zeroMarginBottom, "robotoLight")}>

                                            E-Trade Desk Verification Entity:
                                        </Col>
                                    </Row>
                                    <Row justify="center"  style={{marginTop:25}} >
                                        <Col span={22}>
                                            <img className={styles.logoImg} src={"data:image/png;base64," +companyData.etradeDeskImage} alt="etrade desk image" />
                                        </Col>
                                    </Row>
                                </Col>
                                {companyData.cooperationImage &&
                                    <Col span={12}>
                                        <Row justify="center" style={{marginTop:25}}>
                                            <Col offset={1} lg={22} xs={24} sm={24} md={24} style={{fontSize:18}} className={classNames(styles.zeroMarginBottom, "robotoLight")}>
                                                In cooperation with::
                                            </Col>
                                        </Row>
                                        <Row justify="center"  style={{marginTop:25}} >
                                            <Col span={22}>
                                                <img className={styles.logoImg} src={"data:image/png;base64," +companyData.cooperationImage} alt="cooperation image" />
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </Row>
                        </>
                    }

                <Row justify="center"  style={{marginTop:0}} >
                    <Col lg={22} xs={24} sm={24} md={24} className={styles.borderBetweenContent}></Col>
                    <Col lg={{span:10, offset:2}} xl={{span:10, offset:2}} xxl={{span:6, offset:1}} xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:16, offset:4}}
                         style={{fontSize:18, marginTop:0}} className={classNames(styles.zeroMarginBottom, "robotoLight")}>

                        © 2023 WTPF. All rights reserved.
                    </Col>
                </Row>
                    </>
            }
            </div>
        </>
    );
}