import React, {useEffect, useState} from 'react';
import {ICompany} from "../../../interfaces/Company";
import {InputNumber, notification, Select} from 'antd';
import {IField} from "../../../interfaces/IField";
import {ICategory} from "../../../interfaces/Category";
import {rootServiceURL} from "../../../config";
import axios from "axios";
import {openNotification} from "../../../util/OpenNotification";
import {SortOrder} from "antd/es/table/interface";
import TextArea from "antd/es/input/TextArea";
import {getDateRender} from "../../../util/Utils";
import AdminCrud from "../../../component/admincrud/AdminCrud";

function Companies(){
    const [api, contextHolder] = notification.useNotification();
    const [categories, setCategories] = useState<ICategory[]>([]);

    useEffect(() => {
        loadCategories();
    }, []);

    const loadCategories = () => {
        const url = rootServiceURL() + "minimalgraph/rest/search/Category"
        const criteriaItems = {
            "criteriaItems":[]
        }
        axios.post(url, criteriaItems, {headers: {token: "thisIsAValidTokenButDontUseIt"}}).then(response => {
            if(response.status == 200){
                setCategories(response.data);
            }
            else{
                openNotification(api,'error', 'top', "An error has occurred loading categories")
            }
        }).catch(reason => {
            openNotification(api,'error', 'top', "An error has occurred loading categories "+(reason.response ? reason.response.data : reason));
        });
    }

    const fields: IField<ICompany>[] = [
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.company.localeCompare(b.company),
            update: true,
            show:true,
        },
        {
            title: 'First Name',
            dataIndex: 'registereduserfirstname',
            key: 'registereduserfirstname',
            sorter: (a, b) => a.registereduserfirstname.localeCompare(b.registereduserfirstname),
            show:true,
            update: true,
        },
        {
            title: 'Last Name',
            dataIndex: 'registereduserlastname',
            key: 'registereduserlastname',
            sorter: (a, b) => a.registereduserlastname.localeCompare(b.registereduserlastname),
            show:true,
            update: true,
        },
        {
            title: 'Oficial Registration',
            dataIndex: 'oficialregistration',
            key: 'oficialregistration',
            // sorter: (a, b) => a.oficialregistration > b.oficialregistration ? 1 : -1,
            show:true,
            update: true,
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            show:true,
        },
        {
            title: 'Desk Number',
            dataIndex: 'desknumber',
            key: 'desknumber',
            sorter: (a, b) => a.desknumber == null ? -1 : b.desknumber == null ? 1 : (a.desknumber > b.desknumber ? 1 : -1),
            update:true,
            show:true,
        },
        {
            title: 'Domain Control',
            dataIndex: 'domaincontrol',
            key: 'domaincontrol',
            sorter: (a, b) => a.domaincontrol > b.domaincontrol ? 1 : -1,
            update:true,
            show:true,
        },
        {
            title: 'Payment made',
            dataIndex: 'paymentid',
            key: 'paymentid',
            show:true,
            render: (paymentid) => {
                return paymentid != null ? 'Yes' : 'No'
            },
        },
        {
            title: 'Data completed',
            dataIndex: 'completedata',
            key: 'completedata',
            show:true,
            render: (completedata) => {
                return completedata != null && completedata ? 'Yes' : 'No'
            },
        },

        {
            title: 'Blockchain',
            dataIndex: 'blockchainhash',
            key: 'blockchainhash',
            show:true,
            update: false,
        },
        {
            title: 'Id Issued',
            dataIndex: 'idissued',
            key: 'idissued',
            show:true,
            update: false,
            render: getDateRender()
        },
        {
            title: 'Create At',
            dataIndex: 'createat',
            key: 'createat',
            show:true,
            update: false,
            render: getDateRender()
        },
        {
            title:'Category',
            key: 'categoryid',
            dataIndex: 'categoryid',
            update:true,
            show:true,
            foreignkey:true,
            descriptiveColumn:"category",
            sorter: (a, b) => a.categoryid?.category.localeCompare(b.categoryid?.category),
            render: (categoryid) => {
                return categoryid ? categoryid.category : ''
            },
            // @ts-ignore
            renderInput: () => (
                <Select options={categories.map(category => ({
                        value : category.categoryid,
                        label: category.category
                    }
                ))}/>
            ),
        },
        {
            title: 'Etrade ID',
            dataIndex: 'etradeid',
            key: 'etradeid',
            update:true,
            show:true,
            rules: [{ required: true, message: 'Please input company etrade id!' }]

        },
        {
            title: 'Tax Number',
            dataIndex: 'tax',
            key: 'tax',
            update:true,
            show:true,
            rules: [{ required: true, message: 'Please input company tax number!' }]
        },
        {
            title: 'Established Year',
            dataIndex: 'establishedyear',
            key: 'establishedyear',
            update: true,
            show: false,
            rules: [{ required: true, message: 'Please input company establishedyear!' }],
            renderInput: () => (
                <InputNumber min={1900} />
            )
        },

        {
            title: 'Location',
            dataIndex: 'address',
            key: 'address',
            update:true,
            show:true,
            rules: [{ required: true, message: 'Please input company location!' }]
        },
        {
            title: 'Zip Code',
            dataIndex: 'zipcode',
            key: 'zipcode',
            update:true,
            show:false,
            rules: [{ required: true, message: 'Please input company zipcode!' }]
        },
        {
            title: 'Website',
            dataIndex: 'website',
            key: 'website',
            update:true,
            show:false,
            rules: [{ required: true, message: 'Please input company website!' }]
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            update:true,
            show:false,
            rules: [{ required: true, message: 'Please input company phone!' }],
        },
        {
            title: 'Main Activity',
            dataIndex: 'mainactivity',
            key: 'mainactivity',
            update:true,
            show:false,
            rules: [{ required: true, message: 'Please input company main activity!' }],
            renderInput: () => (
                <TextArea rows={4} placeholder="Main activity" maxLength={254} />
            )
        },
        {
            title: 'Email verified',
            dataIndex: 'emailverified',
            key: 'emailverified',
            sorter: (a, b) => a.emailverified ? 1 : -1,
            render: (emailverified) => (emailverified ? 'Yes' : 'No'),
            update:true,
            show:true,
            rules: [{ required: true, message: 'Please input if this email is verified!' }],
            renderInput: () => (
                <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                </Select>
            ),
        },
        {
            title:'Company Verified',
            key: 'companyverified',
            rules: [{ required: true, message: 'Please input if this company is verified!' }],
            update:true,
            show:false,
            // @ts-ignore
            renderInput: () => (
                <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                </Select>
            ),
        },
    ];
    return (
        <AdminCrud<ICompany> entity="company" columnsToAdd={fields} menu={'companies'}
                             customUpdateUrl={rootServiceURL() + `fullgraph/company/updateCompany`}
                             getUrl={rootServiceURL() + `minimalgraph/company/getCompanies`}/>
    );
}
export default Companies;