import React, {useState} from 'react';
import styles from './Login.module.css'
import {Button, Col, Form, Input, notification, Row} from 'antd';
import classNames from 'classnames';
import {openNotification} from "../../util/OpenNotification";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {rootServiceURL} from "../../config";
import {isEtradeDeskOrAdminByLoggedUser, isEtradeDeskRol, isEtradeDeskRolByLoggedUser} from "../../util/Utils";
import {Footer} from "../../component/footer/Footer";

const fontSizeClass = classNames({
    'default-font-size': true,
    'mobile-font-size': window.innerWidth <= 768,
});
function LoginSmsCode(){
    const [api, contextHolder] = notification.useNotification();
    const [working, setWorking] = useState(false);
    const history = useHistory();
    const onFinish = (values: any) => {
        const url = rootServiceURL() + "minimalgraph/phocusservice/loginSmsCode/"+values.smsCode;
        try {
            setWorking(true);
            axios.get(url).then(value => {
                console.log("value", value)
                if(value.data.success === true){
                    localStorage.setItem('userData', JSON.stringify(value.data));
                    if(isEtradeDeskOrAdminByLoggedUser(value.data?.phocusUser)){
                        history.push("/admin");
                    }
                    else{
                        history.push("/completedata");
                    }
                }
                else{
                    openNotification(api, 'error', 'top', 'Login error: '+value.data.message);
                }
                setWorking(false);
            }).catch(reason => {
                console.log("reason", reason)
                openNotification(api, 'error', 'top', 'Login error: '+(reason.response ? reason.response.data : reason));
                setWorking(false);
            });
        } catch (error) {
            console.error('Error en la solicitud:', error);
            setWorking(false);
        }
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div>
            {contextHolder}
            <Row justify="start" align="middle">
                <Col xs={24} sm={24} md={14} lg={14} xl={14}  className={styles.contentStyle}>
                    <img  src={"/hexagone.png"} />
                </Col>
                <Col  xs={24} sm={24} md={10} lg={10}  xl={10} className={styles.formStyle}>
                    <Row justify="center" align="middle">
                        <img  className={styles.eTradeTitleImg} src={"/e-trade-title.png"}  />
                    </Row>
                    <Row className={styles.tenTopMargin} justify="center" align="middle">
                        <h1 className={styles.loginTitle}>SMS CODE</h1>
                    </Row>
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={(values) => onFinish(values)}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off">
                        <Row className={styles.sixtyTopMargin} justify="center" align="middle">
                            <Col xs={16}>
                                <Form.Item
                                    name={['smsCode']}
                                    label=""
                                    rules={[{ required: true, message: 'Please input your sms code' }]}>
                                    <Input placeholder={"Sms code"} className={styles.defaultInput}  />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className={styles.tenTopMargin} justify="center" align="middle">
                            <Col xs={16}>
                                <Form.Item>
                                    <Button disabled={working} className={styles.fullWidth} type="primary" htmlType="submit">
                                        LOGIN
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center" align="middle">
                            <Col xs={16}>
                                <Button disabled={working} className={classNames(styles.linkButton, styles.fullWidth)}   onClick={() => history.push("/register")} type="link">
                                    SIGN UP
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>
            <Footer/>
        </div>
    );
}
export default LoginSmsCode;