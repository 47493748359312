import React, {useEffect, useState} from 'react';
import styles from './ConfirmEmail.module.css'
import {Button, Checkbox, Col, Form, Input, notification, Row} from 'antd';

import {RouteComponentProps} from "react-router-dom";
import axios from "axios";
import {rootServiceURL} from "../../config";
import {openNotification} from "../../util/OpenNotification";
import {logOut} from "../../util/Utils";

export interface ConfirmEmailProps extends RouteComponentProps<{ cipherEmail: string }> {}

function ConfirmEmail(props: ConfirmEmailProps) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [emailConfirmed, setEmailConfirmed] = useState(false)
    const [error, setError] = useState(null)
    const [api, contextHolder] = notification.useNotification();

    const confirm =  (cipherEmail: string) => {
        const url = rootServiceURL() + "minimalgraph/phocusservice/confirmEmail";

        console.log("CipherEmail", cipherEmail)
        try {
            axios.post(url, cipherEmail, {headers: {'Content-Type': 'text/plain'}} )
                .then(r => {
                    console.log(r);
                    if(r.status == 200){
                        setError(null);
                        setEmailConfirmed(true);
                    }
                }).catch(reason => {
                    openNotification(api, 'error', 'top', "Error verifying email "+(reason.response ? reason.response.data : reason))
                    // @ts-ignore
                    setError("Error verifying email "+(reason.response ? reason.response.data : reason));
                    console.error("Error ",reason);
                });
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    }

    useEffect(() => {
        console.log("props.match ", props.match)
        confirm(props.match.params.cipherEmail);

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isMobile = windowWidth < 768;

    return (
        <>
            {contextHolder}
            <Row style={{ alignItems: 'start', justifyContent: 'start', width: '100%', marginTop:80 }}>
                <Col xs={{offset:1, span:23}} sm={{offset:1, span:23}} md={8} lg={8} xl={8} xxl={8}>

                    <img width={'200px'} src={"/e-trade-title.png"} />
                </Col>
                <Col xs={{offset:1, span:23}} sm={{offset:1, span:23}} md={8} lg={8} xl={8} xxl={8}>
                    <h1 style={{ color: '#003e86'}}>CONFIRM EMAIL</h1>
                </Col>
            </Row>
            <div style={{borderTop: '3px outset #d4d9d6', margin: 20}}/>

            <Row justify="center">
                <Col span={17}>
                    {emailConfirmed ?
                        <div>
                            <h1>Email confirmed correctly, you can log in</h1>
                            <Button className={styles.buttonWidth} type="primary" htmlType="button" onClick={() => props.history.push("/login")}>
                                Login to finalice the Registration
                            </Button>
                        </div>
                        : <h1>{error ? error : "Confirming email..."}</h1>}
                </Col>
            </Row>
        </>
    );
}
export default ConfirmEmail;