import React, {useState} from 'react';
import styles from './resetpassword.module.css';
// @ts-ignore
import commonStyles from '../../component/commonstyles/CommonStyle.css';
import {Button, Checkbox, Col, Form, Input, message, notification, Row} from 'antd';
import classNames from 'classnames';
import {openNotification} from "../../util/OpenNotification";
import axios from "axios";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {rootServiceURL} from "../../config";
import {Footer} from "../../component/footer/Footer";

const fontSizeClass = classNames({
    'default-font-size': true,
    'mobile-font-size': window.innerWidth <= 768,
});
export interface ResetPasswordProps extends RouteComponentProps<{ token: string }> {}

function ResetPassword(props: ResetPasswordProps){

    const [api, contextHolder] = notification.useNotification();
    const [working, setWorking] = useState(false);
    const history = useHistory();
    const onFinish = (values: any) => {

        if(!values.checkbox){
            message.error("You must agree to terms and conditions");
            return;
        }

        const resetRequest = {
            password: values.password,
            token: props.match.params.token,
        };

        const url = rootServiceURL() + "minimalgraph/phocusservice/resetPassword"
        try {
            setWorking(true);
            axios.post(url, resetRequest).then(value => {
                if(value.status === 200){
                    message.success("Password reset successfully");
                    history.push("/login")
                }
                else{
                    openNotification(api, 'error', 'top', 'Reset password error:');
                }
                setWorking(false);
            }).catch(reason => {
                openNotification(api, 'error', 'top', 'Reset password error: '+(reason.response ? reason.response.data : reason));
                setWorking(false);
            });
        } catch (error) {
            setWorking(false)
        }
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div>
            {contextHolder}
            <Row justify="start" align="middle">
                <Col xs={24} sm={24} md={14} lg={14} xl={14}  className={styles.contentStyle}>
                    <img  src={"/hexagone.png"} />
                </Col>
                <Col  xs={24} sm={24} md={10} lg={10}  xl={10} className={styles.formStyle}>
                    <Row justify="center" align="middle">
                        <img  width={'200px'} src={"/e-trade-title.png"} style={{marginTop: 70}} />
                    </Row>
                    <Row style={{marginTop:20}} justify="center" align="middle">
                        <h1 className={styles.changePasswordTitle}>CHANGE PASSWORD</h1>
                    </Row>
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={(values) => onFinish(values)}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off">
                        <Row style={{marginTop:20}} justify="center" align="middle">
                            <Col xs={16}>
                                <Form.Item
                                    label=""
                                    name="password"
                                    rules={[
                                        { required: true, message: 'Please input your password!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).+$/;

                                                if(!value || value.length < 8){
                                                    return Promise.reject(new Error('Password must be at least 8 characters!'));
                                                }

                                                if(!regex.test(value)){
                                                    return Promise.reject(new Error('The password must contain at least one number, one uppercase letter, and one special character!'));
                                                }

                                                return Promise.resolve();
                                            },
                                        })
                                    ]}
                                    hasFeedback={true}
                                >
                                    <Input.Password placeholder={"Password"}  className={commonStyles.defaultTextInput}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{marginTop:20}} justify="center" align="middle">
                            <Col xs={16}>
                                <Form.Item
                                    label=""
                                    name="password2"
                                    rules={[
                                        { required: true, message: 'Please input your password!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The new password that you entered do not match!'));
                                            },
                                        })
                                    ]}
                                    dependencies={['password']}
                                    hasFeedback>
                                    <Input.Password placeholder={"Repeat password"}  className={commonStyles.defaultTextInput}/>
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row justify="center" style={{marginTop: 20}}>
                            <Col xs={16}>
                                <Form.Item label="" name="checkbox" valuePropName="checked" rules={[{required:true, message: 'Please accept!'}]}>
                                    <Checkbox><a href={""}>I agree to the Terms and Conditions and Privacy Notice</a></Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{marginTop:20}} justify="center" align="middle">
                            <Col xs={16}>
                                <Form.Item>
                                    <Button disabled={working} style={{width: '100%'}}  type="primary" htmlType="submit">
                                        RESET PASSWORD
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center" align="middle">
                            <Col xs={16}>
                                <Button disabled={working} className={styles.linkButton} style={{ width: '100%'}}  onClick={() => history.push("/login")} type="link">
                                    GO TO LOGIN
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>
           <Footer/>
        </div>
    );
}
export default ResetPassword;