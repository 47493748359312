import React, {useEffect, useState} from 'react';
import {ICompany} from "../../../interfaces/Company";
import {Button, Col, Form, InputNumber, Modal, notification, Row, Select, Space} from 'antd';
import {IField} from "../../../interfaces/IField";
import {rootServiceURL} from "../../../config";
import axios from "axios";
import {openNotification} from "../../../util/OpenNotification";
import {getDateRender, getUserToken} from "../../../util/Utils";
import {IEtradeDesk} from "../../../interfaces/IEtradeDesk";
import PhocusTable from "../../../component/PhocusTable/PhocusTable";
import AdminMenu from "../AdminMenu";

function CompaniesToFix(){
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const [companyToUpdate, setCompanyToUpdate] = useState<ICompany>()
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const [etradeDesks, setEtradeDesks] = useState<IEtradeDesk[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateFieldValues, setUpdateFieldValues] = useState<ICompany | undefined>(undefined);

    useEffect(() => {
        loadCompanies();
        loadEtradeDesks();
    }, []);

    const loadCompanies = () => {
        const url = rootServiceURL() + `minimalgraph/company/getCompaniesToFix`;
        axios.post(url, {}, {headers: {token: getUserToken()}}).then(response => {
            if(response.status == 200){
                setCompanies(response.data);
            }
            else{
                openNotification(api,'error', 'top', "An error has occurred loading companies")
            }
        }).catch(reason => {
            openNotification(api,'error', 'top', "An error has occurred loading companies "+(reason.response ? reason.response.data : reason));
        });
    }
    const loadEtradeDesks = () => {
        const url = rootServiceURL() + "minimalgraph/rest/search/Etradedesk"
        const criteriaItems = {
            "criteriaItems":[]
        }
        axios.post(url, criteriaItems, {headers: {token: "thisIsAValidTokenButDontUseIt"}}).then(response => {
            if(response.status == 200){
                setEtradeDesks(response.data);
            }
            else{
                openNotification(api,'error', 'top', "An error has occurred loading categories")
            }
        }).catch(reason => {
            openNotification(api,'error', 'top', "An error has occurred loading categories "+(reason.response ? reason.response.data : reason));
        });
    }

    const openUpdateModal = (entity: ICompany) => {
        cleanFormData();
        setCompanyToUpdate(entity);
        setIsModalOpen(true)
    }


    const cleanFormData = () => {
        form.resetFields();
        setCompanyToUpdate(undefined);
        setUpdateFieldValues(undefined);
    }

    const onFinish = (values : any) => {
        let entityToPost = {
            ...updateFieldValues,
            ...values,
        };

        const url = rootServiceURL() + 'minimalgraph/company/updateEtradeDeskID/'+companyToUpdate?.companyid+"/"+values.etradedeskid;

        console.log("Values.. ",values)
        console.log("url.. ",url)

        axios.get(url, {headers: {token: getUserToken()}}).then(response => {
            if(response.status == 200){
                cleanFormData();
                setIsModalOpen(false);
                loadCompanies();
            }
            else{
                openNotification(api,'error', 'top', "An error has occurred updating company")
            }
        }).catch(reason => {
            openNotification(api,'error', 'top', "An error has occurred updating company "+(reason.response ? reason.response.data : reason));
        });
        //
        //
        // const rc = new RestClient(dispatch).token(token).url(url);
        //
        // const update = updateFieldValues && updateFieldValues!.countryid !== undefined && updateFieldValues!.countryid > 0;
        // if(!update){
        //
        //     rc.doPost(entityToPost, (result: any) => {
        //         if (rc.isSuccess()) {
        //             message.success("País creado correctamente")
        //             loadCountrylist();
        //             setIsModalOpen(false);
        //             cleanFormData();
        //         }
        //         else{
        //             message.error( "Error creando País "+rc.getErrors());
        //         }
        //
        //     }, false);
        //
        // }
        // else{
        //     rc.doPut(entityToPost, (result: any) => {
        //         if (rc.isSuccess()) {
        //             message.success("País actualizado correctamente");
        //             loadCountrylist();
        //             setIsModalOpen(false);
        //             cleanFormData();
        //         }
        //         else{
        //             message.error( "Error actualizando País "+rc.getErrors());
        //         }
        //
        //     }, false);
        // }
    }

    const fields: IField<ICompany>[] = [
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.company.localeCompare(b.company),
            update: true,
            show:true,
        },
        {
            title: 'First Name',
            dataIndex: 'registereduserfirstname',
            key: 'registereduserfirstname',
            sorter: (a, b) => a.registereduserfirstname.localeCompare(b.registereduserfirstname),
            show:true,
            update: true,
        },
        {
            title: 'Last Name',
            dataIndex: 'registereduserlastname',
            key: 'registereduserlastname',
            sorter: (a, b) => a.registereduserlastname.localeCompare(b.registereduserlastname),
            show:true,
            update: true,
        },
        {
            title: 'Country',
            key: 'countryid',
            dataIndex: 'countryid',
            update: true,
            show: true,
            foreignkey: true,
            descriptiveColumn: "country",
            sorter: (a, b) => a.countryid?.country.localeCompare(b.countryid?.country),
            render: (countryid) => {
                return countryid ? countryid.country : ''
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            show:true,
        },
        {
            title: 'Desk Number',
            dataIndex: 'desknumber',
            key: 'desknumber',
            sorter: (a, b) => a.desknumber == null ? -1 : b.desknumber == null ? 1 : (a.desknumber > b.desknumber ? 1 : -1),
            update:true,
            show:true,
        },
        {
            title: 'Create At',
            dataIndex: 'createat',
            key: 'createat',
            show:true,
            update: false,
            render: getDateRender()
        },
        {
            title: 'Location',
            dataIndex: 'address',
            key: 'address',
            update:true,
            show:true,
            rules: [{ required: true, message: 'Please input company location!' }]
        },
        {
            title: 'Zip Code',
            dataIndex: 'zipcode',
            key: 'zipcode',
            update:true,
            show:false,
            rules: [{ required: true, message: 'Please input company zipcode!' }]
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            update:true,
            show:false,
            rules: [{ required: true, message: 'Please input company phone!' }],
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (value, record) => {
                return (
                    <Space size="middle">
                        <a onClick={() => openUpdateModal(record)}>Update Etrade desk</a>
                    </Space>
                );
            },
        }
    ];
    return (
        <>
            {contextHolder}
            <Row>
                <Col lg={4} xl={4} xxl={4} xs={0} sm={0} md={0} >
                    <AdminMenu selectedKey={"companiesToFix"}></AdminMenu>
                </Col>
                <Col lg={20} xl={20} xxl={20} xs={0} sm={0} md={0} >
                    <Modal
                        title={"Update Company"}
                        open={isModalOpen}
                        footer={null}
                        onCancel={() => {
                            cleanFormData();
                            setIsModalOpen(false); }}>
                        <Form
                            name="basic"
                            form={form}
                            initialValues={{ remember: false }}
                            onFinish={(values) => onFinish(values)}
                            scrollToFirstError
                            autoComplete="off">

                            <h3>
                                COMPANY: {companyToUpdate?.company}
                            </h3>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                label={"Etrade Desk"}
                                name={"etradedeskid"}
                                rules={[{ required: true, message: 'Please select etrade desk!' }]}
                            >
                                <Select options={etradeDesks.map(etradeDesk => ({
                                        value : etradeDesk.etradedeskid,
                                        label: (etradeDesk.company +" - " +etradeDesk.desknumber)
                                    }
                                ))}/>
                            </Form.Item>
                            <Button type="primary" htmlType="submit">
                                Update Etrade Desk
                            </Button>
                        </Form>
                    </Modal>
                    <PhocusTable columns={fields} dataSource={companies} />
                </Col>

            </Row>

        </>
    );
}
export default CompaniesToFix;