import Register from "../../register/Register";
import {Col, notification, Row} from "antd";
import AdminMenu, {AdminMobileMenu} from "../AdminMenu";
import React, {useEffect} from "react";
import styles from "../../../component/admincrud/AdminCrud.module.css";
import {checkLogged} from "../../../util/Utils";
import {useHistory} from "react-router-dom";

export default function  CreateEtradeDesk(){
    const [api, contextHolder] = notification.useNotification();
    const history = useHistory();

    useEffect(() => {
        checkLogged(history, true);
    }, []);

    return (
        <>
            {contextHolder}
            <Row>
                <Col lg={0} xl={0} xxl={0} xs={24} sm={24} md={24}>
                    <AdminMobileMenu selectedKey={`createEtradeDesk`}></AdminMobileMenu>
                </Col>
            </Row>
            <Row>
                <Col lg={4} xl={4} xxl={4} xs={0} sm={0} md={0} >
                    <AdminMenu selectedKey={`createEtradeDesk`} ></AdminMenu>
                </Col>
                <Col lg={20} xl={20} xxl={20} xs={24} sm={24} md={24} className={styles.separationOnlyPadding}>
                    <Register isFromEtradeDesk={true}></Register>
                </Col>
            </Row>

        </>
    )
}