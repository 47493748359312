import React, {useState} from 'react';
import styles from './Login.module.css'
import {Button, Col, Form, Input, message, notification, Row} from 'antd';
import classNames from 'classnames';
import {openNotification, openNotificationWithTitle} from "../../util/OpenNotification";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {rootServiceURL} from "../../config";
import {encryptMD5} from "../../util/Utils";
import {Footer} from "../../component/footer/Footer";

function Login(){
    const [api, contextHolder] = notification.useNotification();
    const [working, setWorking] = useState(false);
    const history = useHistory();
    const onFinish = (values: any) => {
        const loginRequest = {
            username: values.user.email,
            password: encryptMD5(values.password),
            passwordEncryption: "MD5",
        };
        const url = rootServiceURL() + "minimalgraph/phocusservice/login"
        try {
            setWorking(true);
            axios.post(url, loginRequest).then(value => {

                if(value.status === 200 && (!value.data || value.data.success)){
                    if (value.data === ''){
                        message.success("Successful login, enter the code you received by SMS to continue");
                        history.push("/loginSmsCode");
                    }
                    else{
                        localStorage.setItem('userData', JSON.stringify(value.data));
                        history.push("/admin");
                    }

                }
                else{
                    openNotificationWithTitle(api, 'error', 'top', "Attention",value.data?.message);
                }
                setWorking(false);
            }).catch(reason => {
                console.log("reason", reason)
                openNotificationWithTitle(api, 'error', 'top', "Attention", (reason.response ? reason.response.data : reason));
                setWorking(false);
            });
        } catch (error) {
            console.error('Error en la solicitud:', error);
            setWorking(false);
        }
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div>
            {contextHolder}
            <Row justify="start" align="middle">
                <Col xs={24} sm={24} md={14} lg={14} xl={14} className={styles.contentStyle}>
                    <img src={"/hexagone.png"}/>
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10} className={styles.formStyle}>
                    <Row justify="center" align="middle">
                        <img className={styles.eTradeTitleImg} src={"/e-trade-title.png"}/>
                    </Row>
                    <Row className={styles.tenTopMargin} justify="center" align="middle">
                        <h1 className={styles.loginTitle}>LOGIN</h1>
                    </Row>
                    <Form
                        name="basic"
                        initialValues={{remember: true}}
                        onFinish={(values) => onFinish(values)}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off">
                        <Row className={styles.sixtyTopMargin} justify="center" align="middle">
                            <Col xs={16}>
                                <Form.Item
                                    name={['user', 'email']}
                                    label=""
                                    rules={[{type: 'email', required: true, message: 'Please input your email'}]}>
                                    <Input placeholder={"Email Address"} className={styles.defaultInput}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}} justify="center" align="middle">
                            <Col xs={16}>
                                <Form.Item
                                    label=""
                                    name="password"
                                    rules={[{required: true, message: 'Please input your password!'}]}>
                                    <Input.Password placeholder={"Password"} className={styles.defaultInput}/>
                                </Form.Item>
                                <Row justify="end" align="middle">
                                    <a onClick={() => history.push("/forgotPassword")}
                                       className={classNames(styles.linkButton, styles.defaultInput)} href="">
                                        Forgot or change password?
                                    </a>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={styles.tenTopMargin} justify="center" align="middle">
                            <Col xs={16}>
                                <Form.Item>
                                    <Button disabled={working} className={styles.fullWidth} type="primary"
                                            htmlType="submit">
                                        LOGIN
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center" align="middle">
                            <Col xs={16}>
                                <Button disabled={working} className={classNames(styles.linkButton, styles.fullWidth)}
                                        onClick={() => history.push("/register")} type="link">
                                    SIGN UP
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>
            <Footer/>
        </div>
    );
}
export default Login;