import React, {useEffect} from 'react';
import {logOut} from "../../../util/Utils";
import {useHistory} from "react-router-dom";

export default function LogOut(){
    const history = useHistory();

    useEffect(() => {
        if(history){
            logOut(history);
        }
    }, [history]);

    return (
        <>
            <h2>LOG OUT</h2>
            <h3>CLOSING SESSION...</h3>
        </>
    );
}