import React from 'react';
import {ITax} from "../../../interfaces/Tax";
import {IField} from "../../../interfaces/IField";
import AdminCrud from "../../../component/admincrud/AdminCrud";

export default function Taxes(){
    const columns: IField<ITax>[] = [
        {
            title: 'Tax',
            dataIndex: 'tax',
            key: 'tax',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.tax > b.tax ? 1 : -1,
            show:true,
            update:true,
        },
    ];
    return (
        <AdminCrud<ITax> entity="tax" columnsToAdd={columns} menu={'taxes'}/>
    );
}