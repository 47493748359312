import React from 'react';
import {IActivity} from "../../../interfaces/Activity";
import {IField} from "../../../interfaces/IField";
import AdminCrud from "../../../component/admincrud/AdminCrud";
export default function Activities(){
    // @ts-ignore
    const columns: IField<IActivity>[] = [
        {
            title: 'Activity',
            dataIndex: 'activity',
            key: 'activity',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.activity > b.activity ? 1 : -1,
            show:true,
            update:true,
        },

    ];
    return (
        <AdminCrud<IActivity> entity="activity" columnsToAdd={columns} menu={'activities'}/>
    );
}