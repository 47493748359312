import React from 'react';
import {ICountry} from "../../../interfaces/Country";
import {IField} from "../../../interfaces/IField";
import AdminCrud from "../../../component/admincrud/AdminCrud";

function Countries(){
    const columns: IField<ICountry>[] = [
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.country > b.country ? 1 : -1,
            show:true,
            update:true,
        },

    ];
    return (
        <AdminCrud<ICountry> entity="country" columnsToAdd={columns} menu={'countries'}/>
    );
}
export default Countries;