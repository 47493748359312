import React, {useEffect, useState} from 'react';
import styles from './Register.module.css'
// @ts-ignore
import commonStyles from '../../component/commonstyles/CommonStyle.css'
import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber, message,
    Modal,
    notification,
    Row,
    Select,
    Upload,
    UploadFile
} from 'antd';
import axios from 'axios';
import {rootServiceURL} from "../../config";
import {openNotification} from "../../util/OpenNotification";
import {useHistory} from "react-router-dom";
import {ICountry} from "../../interfaces/Country";
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import 'antd-country-phone-input/dist/index.css';
import {base64FromArrayBuffer, fileToBytes, filterSelectOptions, isEtradeDeskRol} from "../../util/Utils";
import {RcFile} from "antd/es/upload";
import {PlusOutlined} from "@ant-design/icons";
import {uploadButton} from "../../component/Commons";


function Register({isFromAdmin,isFromEtradeDesk}:{isFromAdmin?:boolean,isFromEtradeDesk?:boolean}) {
    const [api, contextHolder] = notification.useNotification();
    const [working, setWorking] = useState(false);
    const [countries, setCountries] = useState<ICountry[]>([]);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();

    const [fileList, setFileList] = useState<any[]>([]);
    const [file2List, setFile2List] = useState<any[]>([]);
    // const [file3List, setFile3List] = useState<any[]>([]);
    const [file4List, setFile4List] = useState<any[]>([]);
    const [file5List, setFile5List] = useState<any[]>([]);

    const [preview, setPreview] = useState<{
        previewOpen: boolean,
        previewImage: string | undefined,
        previewTitle: string | undefined
    } | null>(null);

    const [memberImage, setMemberImage] = useState(null);
    const [member2Image, setMember2Image] = useState(null);
    // const [cooperationImage, setCooperationImage] = useState(null);
    const [cooperationCompanyImage, setCooperationCompanyImage] = useState(null);
    const [etradeDeskImage, setEtradeDeskImage] = useState(null);

    const history = useHistory();
    const onFinish = (values: any) => {
        if(!values.phone || !values.phone.phone || !values.phone.code){
            openNotification(api, 'error', 'top', "You must fill in the phone number");
            return;
        }

        if(!values.checkbox){
            message.error("You must agree to terms and conditions");
            return;
        }

        let entityToPost = {...values, countryid:{countryid: values.countryid}, phone: ("+"+values.phone.code+values.phone.phone)};
        delete entityToPost.checkbox;
        delete entityToPost.checkbox2;
        delete entityToPost.checkbox3;
        delete entityToPost.password2;

        let url = rootServiceURL() + "minimalgraph/phocusservice/"

        if (isFromEtradeDesk){
            url+="registerEtradeDesk";

            entityToPost = {
                ...entityToPost,
                // cooperationImage: cooperationImage ? base64FromArrayBuffer(cooperationImage) : null,
                etradeDeskImage: etradeDeskImage ? base64FromArrayBuffer(etradeDeskImage) : null,
                members: [{
                    name: entityToPost.membername,
                    email: entityToPost.memberemail,
                    rol: entityToPost.memberrol,
                    image: memberImage ? base64FromArrayBuffer(memberImage) : null,
                }]
            }

            if(entityToPost.membername2 && entityToPost.memberemail2 && entityToPost.memberrol2 && member2Image){
                entityToPost.members.push({
                    name: entityToPost.membername2,
                    email: entityToPost.memberemail2,
                    rol: entityToPost.memberrol2,
                    image: member2Image ? base64FromArrayBuffer(member2Image) : null,
                })
            }

            delete entityToPost.membername;
            delete entityToPost.memberemail;
            delete entityToPost.memberrol;
            delete entityToPost.memberimage;
            delete entityToPost.membername2;
            delete entityToPost.memberemail2;
            delete entityToPost.memberrol2;
            delete entityToPost.memberimage2;
            delete entityToPost.cooperationimage;
            delete entityToPost.cooperationcompanyimage;
            delete entityToPost.logoimage;

        }
        else{
            entityToPost = {
                ...entityToPost,
                cooperationCompanyImage: cooperationCompanyImage ? base64FromArrayBuffer(cooperationCompanyImage) : null,
            }
            url+="registerCompany/"+((isFromAdmin || isFromEtradeDesk) ? "true" : "false");
            delete entityToPost.cooperationcompanyimage;
        }

        setWorking(true);
        let userDataJson = localStorage.getItem('userData');

        let tokenObtained = 'thisIsAValidTokenButDontUseIt';

        if (userDataJson) {

            let userData = JSON.parse(userDataJson);
            tokenObtained = userData.token;
        }
        axios.post(url, entityToPost, {headers: {token: tokenObtained}}).then(response => {

            if(response.status === 200){
                if (isFromAdmin){
                    openNotification(api,'success', 'top', "Registration successful with your admin account")
                    history.push("/admin/companies");
                }
                else if(isFromEtradeDesk){
                    openNotification(api,'success', 'top', "Registration successful with your admin account")
                    history.push("/admin/etradedesk");
                }
                else{
                    openNotification(api,'success', 'top', "Registration successful, check your confirmation email")
                    history.push("/confirmEmailModal");
                }
            }
            else{
                openNotification(api,'error', 'top', "An error has occurred in the registry")
            }

            setWorking(false);
        }).catch(reason => {
            console.log(reason)
            openNotification(api,'error', 'top', "An error has occurred in the registry "+(reason.response ? reason.response.data : reason));
            setWorking(false);
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        openNotification(api,'error', 'top', "Review the errors on the form")
    };

    const loadCountries = () => {
        const url = rootServiceURL() + "minimalgraph/rest/search/Country"
        const criteriaItems = {
            "criteriaItems":[]
        }
        axios.post(url, criteriaItems, {headers: {token: "thisIsAValidTokenButDontUseIt"}}).then(response => {
            if(response.status == 200){
                setCountries(response.data);
            }
            else{
                openNotification(api,'error', 'top', "An error has occurred loading countries")
            }
        }).catch(reason => {
            openNotification(api,'error', 'top', "An error has occurred loading countries "+(reason.response ? reason.response.data : reason));
        });
    }

    const handleChange = (value: { value: string; label: React.ReactNode }) => {
        console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
    };

    useEffect(() => {
        loadCountries();
        return () => {
        };
    }, []);

    const  handleFileChange =  (info: any, setValue: Function) => {
        fileToBytes(info).then((value: any) => {
            if(value){
                message.success("Image set sucessfully")
                setValue(value);
            }
        }).catch((reason: { response: { data: any; }; }) => {
            message.error("An error has occurred loading profile image "+(reason.response ? reason.response.data : reason));
        })
    };

    const handleFileListChange = (setValueList: Function, setValue: Function, {fileList: newFileList}: any) => {
        setValueList(newFileList);

        if(newFileList.length > 0){
            handleFileChange(newFileList[0].originFileObj, setValue);
        }
    }

    const getBase64 = (file: RcFile) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const handlePreview = async (file: UploadFile) => {
        if(file.uid === '-1'){
            file.preview = file.thumbUrl;
        }
        else{
            if (!file.url && !file.preview) {
                // @ts-ignore
                file.preview = await getBase64(file.originFileObj!);
            }
        }

        setPreview({
            previewOpen: true,
            previewImage: file.url || file.preview,
            previewTitle: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
        })
    };

   return (
       <Row justify="center">
           <Modal open={preview?.previewOpen} title={preview?.previewTitle} footer={null} onCancel={() => setPreview(null)}>
               <img alt="example" style={{ width: '100%' }} src={preview?.previewImage} />
           </Modal>
           <Col xs={isFromAdmin || isFromEtradeDesk ?24:20} sm={isFromAdmin || isFromEtradeDesk?24:20}
                md={isFromAdmin || isFromEtradeDesk?24:18} lg={isFromAdmin || isFromEtradeDesk?24:12}
                xl={isFromAdmin || isFromEtradeDesk?24:10} xxl={isFromAdmin || isFromEtradeDesk?24:7}
                className={styles.container}>
               {contextHolder}
               <Row justify="center">
                   <img width={'150px'} src={"/e-trade-title.png"} alt={""} style={{marginTop: 40}} />
               </Row>
               <Row justify="center">
                   <h1 className={styles.createAccountTitle}>{isFromAdmin?"Create Company":isFromEtradeDesk?"Create E-trade Desk":"Create Account"}</h1>
               </Row>
               <ConfigProvider locale={en}>
                   <Form
                       style={{width:'100%'}}
                       name="basic"
                       initialValues={{ remember: true }}
                       labelCol={{span:24}}
                       onFinish={onFinish}
                       onFinishFailed={onFinishFailed}
                       form={form}
                       autoComplete="off">
                       <Row justify="center">
                           <Col span={14}>
                               <Form.Item
                                   label="First Name"
                                   name="registereduserfirstname"
                                   rules={[{ required: true, message: 'Please input your First Name!' }]}>
                                   <Input  className={commonStyles.defaultTextInput}/>
                               </Form.Item>
                           </Col>
                       </Row>
                       <Row justify="center">
                           <Col span={14}>
                               <Form.Item
                                   label="Last Name"
                                   name="registereduserlastname"
                                   rules={[{ required: true, message: 'Please input your Last Name!' }]}>
                                   <Input  className={commonStyles.defaultTextInput}/>
                               </Form.Item>
                           </Col>
                       </Row>
                       {isFromEtradeDesk?(
                           <Row justify="center">
                               <Col span={14}>
                                   <Form.Item
                                       label="Desk Number"
                                       name="desknumber"
                                       rules={[{ required: true, message: 'Please input your Desk Number!' }]}>
                                       <Input  className={commonStyles.defaultTextInput}/>
                                   </Form.Item>
                               </Col>
                           </Row>
                       ):
                           <Row justify="center">
                               <Col span={14}>
                                   <Form.Item
                                       name="zipcode"
                                       label="Zip Code"
                                       rules={[{ required: true, message: 'Please input your Zip Code'  }]}>
                                       <Input  className={commonStyles.defaultTextInput}/>
                                   </Form.Item>
                               </Col>
                           </Row>
                       }

                       <Row justify="center">
                           <Col span={14}>
                               <Form.Item
                                   name="email"
                                   label="Email"
                                   rules={[{ type: 'email', required: true, message: 'Please input your email' }]}>
                                   <Input  className={commonStyles.defaultTextInput}/>
                               </Form.Item>
                           </Col>
                       </Row>
                       <Row justify="center">
                           <Col span={14}>
                               <Form.Item
                                   label="Password"
                                   name="password"
                                   rules={[
                                       { required: true, message: 'Please input your password!' },
                                       ({ getFieldValue }) => ({
                                           validator(_, value) {
                                               const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).+$/;

                                               if(!value || value.length < 8){
                                                   return Promise.reject(new Error('Password must be at least 8 characters!'));
                                               }

                                               if(!regex.test(value)){
                                                   return Promise.reject(new Error('The password must contain at least one number, one uppercase letter, and one special character!'));
                                               }

                                               return Promise.resolve();
                                           },
                                       })
                                   ]}
                                   hasFeedback={true}
                               >
                                   <Input.Password placeholder={"Password"}  className={commonStyles.defaultTextInput}/>
                               </Form.Item>
                           </Col>
                       </Row>
                       <Row justify="center">
                           <Col span={14}>
                               <Form.Item
                                   label="Repeat Password"
                                   name="password2"
                                   rules={[
                                       { required: true, message: 'Please input your password!' },
                                       ({ getFieldValue }) => ({
                                           validator(_, value) {
                                               if (!value || getFieldValue("password") === value) {
                                                   return Promise.resolve();
                                               }
                                               return Promise.reject(new Error('The new password that you entered do not match!'));
                                           },
                                       })
                                   ]}
                                   dependencies={['password']}
                                   hasFeedback>
                                   <Input.Password  className={commonStyles.defaultTextInput}/>
                               </Form.Item>
                           </Col>
                       </Row>
                       <Row justify="center">
                           <Col span={14}>
                               <Form.Item
                                   label="Company"
                                   name="company"
                                   rules={[{ required: true, message: 'Please input your Company!' }]}>
                                   <Input  style={{ height: '40px', fontFamily: 'Roboto', fontSize: '14px', fontWeight: 300, width: '100%'}}/>
                               </Form.Item>
                           </Col>
                       </Row>
                       <Row justify="center">
                           <Col span={14}>
                               <Form.Item
                                   label="City"
                                   name="address"
                                   rules={[{ required: true, message: 'Please input your City!' }]}>
                                   <Input  className={commonStyles.defaultTextInput}/>
                               </Form.Item>
                           </Col>
                       </Row>
                       <Row justify="center">
                           <Col span={14}>
                               <Form.Item
                                   label="Country"
                                   name="countryid"
                                   rules={[{ required: true, message: 'Please select your Country!' }]}>
                                   <Select
                                       style={{ width: '100%' }}
                                       showSearch
                                       filterOption={filterSelectOptions()}
                                       filterSort={(optionA, optionB) =>
                                           (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                       onChange={handleChange}
                                       options={
                                           countries.map(country => ({
                                                   value : country.countryid,
                                                   label: country.country
                                               }
                                           ))}/>
                               </Form.Item>
                           </Col>
                       </Row>
                       <Row justify="center">
                           <Col span={14}>
                               <Form.Item
                                   label="Mobile Number"
                                   name="phone"
                                   initialValue={{
                                       short: 'us',
                                   }}
                                   rules={[{ required: true, message: 'Please input your Mobile Number!' }]}
                               >
                                   <CountryPhoneInput inline={true}/>
                               </Form.Item>
                           </Col>
                       </Row>
                       {isFromAdmin &&
                           <Row justify="center">
                               <Col span={14}>
                                   <Form.Item
                                       labelCol={{ span: 24 }}
                                       label="Cooperation With"
                                       name="cooperationcompanyimage">
                                       <Upload
                                           listType="picture-card"
                                           beforeUpload={( file) => {
                                               return false;
                                           }}
                                           accept={".png, .jpg, .jpeg"}
                                           onRemove={() => setCooperationCompanyImage(null)}
                                           fileList={file5List}
                                           onPreview={handlePreview}
                                           onChange={(e) => handleFileListChange(setFile5List, setCooperationCompanyImage, e)}
                                       >
                                           {file5List.length === 0 && uploadButton}
                                       </Upload>
                                   </Form.Item>
                               </Col>
                           </Row>
                       }
                       {isFromEtradeDesk &&
                           <>
                               <Row justify="center">
                                   <Col span={14}>
                                       <Form.Item
                                           labelCol={{ span: 24 }}
                                           label="Etrade Desk Logo"
                                           name="logoimage"
                                           rules={[{ required: true, message: 'Please select your etrade desk image!' }]}
                                       >
                                           <Upload
                                               listType="picture-card"
                                               beforeUpload={( file) => {
                                                   return false;
                                               }}
                                               accept={".png, .jpg, .jpeg"}
                                               onRemove={() => setEtradeDeskImage(null)}
                                               fileList={file4List}
                                               onPreview={handlePreview}
                                               onChange={(e) => handleFileListChange(setFile4List, setEtradeDeskImage, e)}
                                           >
                                               {file4List.length === 0 && uploadButton}
                                           </Upload>
                                       </Form.Item>
                                   </Col>
                               </Row>
                               {/*<Row justify="center">*/}
                               {/*    <Col span={14}>*/}
                               {/*        <Form.Item*/}
                               {/*            labelCol={{ span: 24 }}*/}
                               {/*            label="Cooperation With"*/}
                               {/*            name="cooperationimage">*/}
                               {/*            <Upload*/}
                               {/*                listType="picture-card"*/}
                               {/*                beforeUpload={( file) => {*/}
                               {/*                    return false;*/}
                               {/*                }}*/}
                               {/*                onRemove={() => setCooperationImage(null)}*/}
                               {/*                fileList={file3List}*/}
                               {/*                onPreview={handlePreview}*/}
                               {/*                onChange={(e) => handleFileListChange(setFile3List, setCooperationImage, e)}*/}
                               {/*            >*/}
                               {/*                {file3List.length === 0 && uploadButton}*/}
                               {/*            </Upload>*/}
                               {/*        </Form.Item>*/}
                               {/*    </Col>*/}
                               {/*</Row>*/}
                               <Row justify="center">
                                   <Col span={14}>
                                       <Form.Item
                                           label="Member Name"
                                           name="membername"
                                           rules={[{ required: true, message: 'Please input your member name!' }]}>
                                           <Input  className={commonStyles.defaultTextInput}/>
                                       </Form.Item>
                                   </Col>
                               </Row>

                               <Row justify="center">
                                   <Col span={14}>
                                       <Form.Item
                                           label="Member Email"
                                           name="memberemail"
                                           rules={[{ required: true, message: 'Please input your member email!' }]}>
                                           <Input  className={commonStyles.defaultTextInput}/>
                                       </Form.Item>
                                   </Col>
                               </Row>

                               <Row justify="center">
                                   <Col span={14}>
                                       <Form.Item
                                           label="Member Rol"
                                           name="memberrol"
                                           rules={[{ required: true, message: 'Please input your member rol!' }]}>
                                           <Input  className={commonStyles.defaultTextInput}/>
                                       </Form.Item>
                                   </Col>
                               </Row>

                               <Row justify="center">
                                   <Col span={14}>
                                       <Form.Item
                                           labelCol={{ span: 24 }}
                                           label="Member image"
                                           name="memberimage"
                                           rules={[{ required: true, message: 'Please select your member image!' }]}
                                       >
                                           <Upload
                                               listType="picture-card"
                                               beforeUpload={( file) => {
                                                   return false;
                                               }}
                                               accept={".png, .jpg, .jpeg"}
                                               onRemove={() => setMemberImage(null)}
                                               fileList={fileList}
                                               onPreview={handlePreview}
                                               onChange={(e) => handleFileListChange(setFileList, setMemberImage, e)}

                                           >
                                               {fileList.length === 0 && uploadButton}
                                           </Upload>
                                       </Form.Item>
                                   </Col>
                               </Row>

                               <Row justify="center">
                                   <Col span={14}>
                                       <Form.Item
                                           label="Member 2 Name"
                                           name="membername2">
                                           <Input  className={commonStyles.defaultTextInput}/>
                                       </Form.Item>
                                   </Col>
                               </Row>

                               <Row justify="center">
                                   <Col span={14}>
                                       <Form.Item
                                           label="Member 2 Email"
                                           name="memberemail2">
                                           <Input  className={commonStyles.defaultTextInput}/>
                                       </Form.Item>
                                   </Col>
                               </Row>

                               <Row justify="center">
                                   <Col span={14}>
                                       <Form.Item
                                           label="Member 2 Rol"
                                           name="memberrol2">
                                           <Input  className={commonStyles.defaultTextInput}/>
                                       </Form.Item>
                                   </Col>
                               </Row>

                               <Row justify="center">
                                   <Col span={14}>
                                       <Form.Item
                                           labelCol={{ span: 24 }}
                                           label="Member 2 image"
                                           name="memberimage2">
                                           <Upload
                                               listType="picture-card"
                                               beforeUpload={( file) => {
                                                   return false;
                                               }}
                                               accept={".png, .jpg, .jpeg"}
                                               onRemove={() => setMember2Image(null)}
                                               fileList={file2List}
                                               onPreview={handlePreview}
                                               onChange={(e) => handleFileListChange(setFile2List, setMember2Image, e)}
                                           >
                                               {file2List.length === 0 && uploadButton}
                                           </Upload>
                                       </Form.Item>
                                   </Col>
                               </Row>
                           </>
                       }

                       <Row justify="center" style={{marginTop: 20}}>
                           <Col span={14}>
                               <Form.Item label="" name="checkbox" valuePropName="checked" rules={[{required:true, message: 'Please accept!'}]}>
                                   <Checkbox><a href={"https://lawwwing.com/documents/9414325e-8c53-4b03-b84b-1d79a2bcbe81/privacy-policy/en/"} target="_blank"> I have read and accept the “Privacy and Cookies Policy”</a></Checkbox>
                               </Form.Item>
                           </Col>
                       </Row>

                       <Row justify="center">
                           <Col span={14}>
                               <Form.Item label="" name="checkbox2" valuePropName="checked" rules={[{required:true, message: 'Please accept!'}]}>
                                   <Checkbox><a href={"https://lawwwing.com/documents/9414325e-8c53-4b03-b84b-1d79a2bcbe81/terms-and-conditions/en/"} target="_blank">I have read and accept the “Terms and Conditions of Use”</a></Checkbox>
                               </Form.Item>
                           </Col>
                       </Row>

                       <Row justify="center">
                           <Col span={14}>
                               <Form.Item label="" name="checkbox3" valuePropName="checked" rules={[{required:true, message: 'Please accept!'}]}>
                                   <Checkbox>I agree to receive email communications from WTPF and know
                                       more about security solutions.</Checkbox>
                               </Form.Item>
                           </Col>
                       </Row>
                       <Row justify="center" style={{marginTop: 20}}>
                           <Col span={14}>
                               <Form.Item>
                                   <Button disabled={working} className={styles.registerButton}  type="primary"  onClick={() => {
                                       if (isFromEtradeDesk || isEtradeDeskRol()) {
                                           form.submit();
                                       }else {
                                           setOpen(true)
                                       }
                                    }
                                   }>
                                       SIGN UP
                                   </Button>
                               </Form.Item>
                           </Col>
                       </Row>
                       <Modal open={open} okText={"Sign up"} onOk={() => {
                           setOpen(false);
                           form.submit()
                       }} onCancel={() => setOpen(false)}
                              cancelButtonProps={{ style: { display: 'none' } }}>
                           <Form.Item
                               label={<div>
                                   <p>If you have an etrade desk number, enter it.</p>
                                   <p style={{paddingBottom: 20}}>If you have NOT an etrade number, continue clicking “Sign up”.</p>
                               </div>}
                               name="desknumber">
                               <InputNumber style={{width:'100%'}}  className={commonStyles.defaultTextInput}/>
                           </Form.Item>
                       </Modal>
                   </Form>
               </ConfigProvider>
           </Col>
       </Row>
   );
}
export default Register;