import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
function PaypalComponent({ handleSubmitClicked}) {

    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [{
                description: "E-Trade subscription",
                amount: {
                    value: 65.00
                }
            }]
        });
    };

    const onApprove = async (data, actions) => {
        const order = await actions.order;
        handleSubmitClicked(order)
    };

    const onError = () => {
        alert('Transaction failed');
    };

    return (
        <PayPalScriptProvider
            options={{
                "client-id": "AdenXc8dzUMCVL8u4A_eL-xjXNIB6tKQHQz75XaYvW6_X8wgj0ddg6ehZndF1LmmFqBdMMUiGSCTscgA",
                "currency":"CHF",
                "debug": false,
                "locale": "en_US"
            }}

        >
            <PayPalButtons
                locale={"en-US"}
                createOrder={createOrder}
                onApprove={onApprove}
                onError={onError}
                fundingSource="card"
            />
        </PayPalScriptProvider>
    );
}

export default PaypalComponent;
