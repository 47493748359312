import React from 'react';
import {Menu, MenuProps} from 'antd';
import {
    FileDoneOutlined,
    FlagOutlined, FolderAddOutlined,
    HomeOutlined, LoginOutlined,
    PicLeftOutlined, PlusOutlined,
    SafetyCertificateOutlined
} from '@ant-design/icons';
import styles from "./companies/companies.module.css";
import {Link} from "react-router-dom";
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    roles?:number[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
        roles
    } as MenuItem;
}
const items = [
    { label: 'Create company', key: 'createCompany', icon: <PlusOutlined />, link: '/admin/createCompany', roles:[1, 2] },
    { label: 'Companies', key: 'companies', icon: <HomeOutlined />, link: '/admin/companies', roles:[1,2] },
    { label: 'Companies to Fix', key: 'companiesToFix', icon: <HomeOutlined />, link: '/admin/companiesToFix', roles:[1] },
    { label: 'Create E-trade Desk', key: 'createEtradeDesk', icon: <FolderAddOutlined />, link: '/admin/createEtradeDesk', roles:[1] },
    { label: 'E-trade Desks', key: 'etradedesk', icon: <FolderAddOutlined />, link: '/admin/etradedesk', roles:[1] },
    { label: 'My E-trade Desk', key: 'etradedesk', icon: <FolderAddOutlined />, link: '/admin/etradedesk', roles:[2] },
    // { label: 'Users', key: 'users', icon: <UserOutlined />, link: '/admin/users' },
    { label: 'Countries', key: 'countries', icon: <FlagOutlined />, link: '/admin/countries', roles:[1] },
    { label: 'Categories', key: 'categories', icon: <PicLeftOutlined />, link: '/admin/categories', roles:[1] },
    { label: 'Activities', key: 'activities', icon: <FileDoneOutlined />, link: '/admin/activities', roles:[1] },
    { label: 'Certificate types', key: 'certificateTypes', icon: <SafetyCertificateOutlined />, link: '/admin/certificateTypes', roles:[1] },
    { label: 'Log Out', key: 'logout', icon: <LoginOutlined />, link: '/admin/logout', roles:[1,2] },
    // { label: 'Taxes', key: 'taxes', icon:<MoneyCollectOutlined />, link: '/admin/taxes' },
];

function hasRequiredRole(userRoles: number[], requiredRoles: number[]): boolean {

    return requiredRoles.some(role => userRoles.includes(role));
}

function filterMenuItems(): any[] {

    let result: any[] = [];
    const userData = localStorage.getItem('userData');
    if (userData){

        const userRoles = JSON.parse(userData).phocusUser?.roles;
        const userRoleIds = userRoles.map((ur:any)=>ur.roleid.roleid);
        result=items.filter(item => !item.roles || hasRequiredRole(userRoleIds, item.roles));
    }

    return result;
}

function AdminMenu({ selectedKey }: { selectedKey: string, }) {

    const filteredItems = filterMenuItems();
    return (

    <Menu defaultSelectedKeys={[selectedKey]} mode="inline" className={styles.menu}>
        {filteredItems.map((item:any) => {

            let result;

            if (item){

                result =
                    <Menu.Item key={item.key} icon={item.icon}>
                        <Link to={item.link}>{item.label}</Link>
                    </Menu.Item>

            }else{

                result=<></>;
            }

            return result;
        })}
    </Menu>

    );
}

export function AdminMobileMenu({ selectedKey }: { selectedKey: string }){
    const filteredItems = filterMenuItems();
    return (
        <Menu defaultSelectedKeys={[selectedKey]} mode="horizontal" className={styles.menuMobile}>
            {filteredItems.map((item:any) => {

                let result;

                if (item){

                    result =
                        <Menu.Item key={item.key} icon={item.icon}>
                            <Link to={item.link}>{item.label}</Link>
                        </Menu.Item>

                }else{

                    result=<></>;
                }

                return result;
            })}
        </Menu>
    );
}
export default AdminMenu;

