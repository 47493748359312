import {
    serverName, CONFIG, CUSTOM_SERVICES_FOR_ENTITIES, CUSTOM_SERVICES_FOR_REPORTS, server, CUSTOM_FIELD_RENDERERS
} from "./Settings";

export {CONFIG, CUSTOM_SERVICES_FOR_ENTITIES,  serverName};

/**
 * login process will fill next object
 */

export const loggedUser = {};

export const rootServiceURL = () => {
    return CONFIG.restService.restServiceRoot;
}

export const getWebSocketURL = () => {
    return CONFIG.restService.ws;
}

export const getKpiWebSocketURL = () => {
    return CONFIG.restService.wsKpi;
}

export const kpiRootServiceURL = () => {
    return CONFIG.restService.kpiUrl;
}

export const UIURLForEntity = (entityName) => {
    if (entityName == null) entityName = "";
    const serviceURL = CUSTOM_SERVICES_FOR_ENTITIES[entityName];
    return (serviceURL ? serviceURL : CONFIG.restService.restServiceRoot) + CONFIG.restService.uiService + entityName;
}

export const URLForReport = (reportName) => {
    const serviceURL = CUSTOM_SERVICES_FOR_REPORTS[reportName];
    return (serviceURL ? serviceURL : CONFIG.restService.restServiceRoot) + CONFIG.restService.reportService
}

export const URLForKPIResource = (entityName, fullgraph = false) => {
    let service = fullgraph ? CONFIG.restService.fullGraphDataService : CONFIG.restService.dataService;
    return CONFIG.restService.kpiUrl + service;
}

export const URLForReportParameters = (reportName) => {
    const urlForReport = URLForReport(reportName);
    let serviceURL = urlForReport ? urlForReport : CONFIG.restService.restServiceRoot + CONFIG.restService.reportService;
    serviceURL += "reportParameters/" + reportName;
    return serviceURL;
}

export const URLForReportExecution = reportDefinition => {
    const {reportName, reportType, url} = reportDefinition;
    let toReturn = URLForReport(reportName);
    if (url == null) {
        if (reportType == "excel") {
            toReturn += "excelReport";
        } else {
            toReturn += "report";
        }
    } else {
        const parts = toReturn.split("/");
        const lastPartLength = parts[parts.length - 2].length;
        const previousPartLength = parts[parts.length - 3].length;
        toReturn = toReturn.substr(0, toReturn.length - (lastPartLength + previousPartLength + 2));
        toReturn += url;
    }
    return toReturn;
}

export const dataServiceURLForEntity = (entityName) => {
    const serviceURL = CUSTOM_SERVICES_FOR_ENTITIES[entityName];
    return (serviceURL ? serviceURL : CONFIG.restService.restServiceRoot) + CONFIG.restService.dataService;
}

export const dataServiceURLForCompleteGraphEntity = entityName => {
    const serviceURL = CUSTOM_SERVICES_FOR_ENTITIES[entityName];
    return (serviceURL ? serviceURL : CONFIG.restService.restServiceRoot) + CONFIG.restService.completeGraphDataService;
}

export const dataServiceURLForFullGraphEntity = (entityName) => {
    const serviceURL = CUSTOM_SERVICES_FOR_ENTITIES[entityName];
    return (serviceURL ? serviceURL : CONFIG.restService.restServiceRoot) + CONFIG.restService.fullGraphDataService;
}

export const dataServiceURLForSage = (entityName) => {
    const serviceURL = CUSTOM_SERVICES_FOR_ENTITIES[entityName];
    return (serviceURL ? serviceURL : CONFIG.restService.restServiceRoot) + "minimalgraph/sage/";
}

export const userHasSomeRoleOf = (validRoles, currentRoles = null) => {
    if (currentRoles == null) currentRoles = loggedUser.phocusUser.roles;
    if (currentRoles && validRoles) {
        for (let a = 0; a < currentRoles.length; a++) {
            for (let b = 0; b < validRoles.length; b++) {
                if (currentRoles[a].roleid.role == validRoles[b]) {
                    return true;
                }
            }
        }
    }
    return false;
}

export const fieldRendererForType = typeName => CUSTOM_FIELD_RENDERERS[typeName]
