import React, {useEffect,  useState} from 'react';
import {Button, Form, Image, Input, List, message, Modal, notification, Popconfirm, Upload, UploadFile} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import axios from "axios";
import {Col, Row, Space} from 'antd';
import AdminMenu, {AdminMobileMenu} from "../../../src/pages/admin/AdminMenu";
import {rootServiceURL} from "../../config";
import {openNotification} from "../../util/OpenNotification";
import {base64FromArrayBuffer, checkLogged, fileToBytes, getUserToken, logOut} from "../../util/Utils";
import {useHistory} from "react-router-dom";
import PhocusTable from "../PhocusTable/PhocusTable";
import PhocusList from "../../component/PhocusList";
import {IField} from "../../interfaces/IField";
import styles from './AdminCrud.module.css';
import {verifyCompany} from "../../pages/admin/companies/action";
import {ICompany} from "../../interfaces/Company";
import {handleFileListChange, handlePreview, uploadButton} from "../Commons";

interface IEntity {
    id: number;
    name: string;
}
interface CrudProps<T extends IEntity> {
    entity: string;
    columnsToAdd?: IField<T>[];
    menu: string;
    getUrl?:string;
    customUpdateUrl?:string;
}

export default function AdminCrud<T extends IEntity>(props: CrudProps<T>) {
    const [list, setList] = useState<T[]>([]);
    const [api, contextHolder] = notification.useNotification();
    const [id, setId] = useState(0);
    const history = useHistory();
    const [updateFieldValues, setUpdateFieldValues] = useState<{ [key: string]: any }>({});
    const [defaultColumns, setDefaultColumns] = useState<ColumnsType<T>>([])

    const [fileList, setFileList] = useState<any[]>([]);
    const [file2List, setFile2List] = useState<any[]>([]);

    const [preview, setPreview] = useState<{
        previewOpen: boolean,
        previewImage: string | undefined,
        previewTitle: string | undefined
    } | null>(null);

    const [logoImage, setLogoImage] = useState(null);
    const [cooperationImage, setCooperationImage] = useState(null);
    const companyExtraColumns : IField<T>[] = ([
        {
            title: 'Logo',
            dataIndex: 'logoImage',
            key: 'logoImage',

            renderInput :() => {
                return(
                    <Upload
                        listType="picture-card"
                        beforeUpload={( file) => {
                            return false;
                        }}
                        accept={".png, .jpg, .jpeg"}
                        onRemove={() => setLogoImage(null)}
                        fileList={fileList}
                        onPreview={(e) => handlePreview(e, setPreview)}
                        onChange={(e) => handleFileListChange(setFileList, setLogoImage, e)}
                    >
                        {fileList.length === 0 && uploadButton}
                    </Upload>
                )}
        },
        {
            title: 'Cooperation Image',
            dataIndex: 'cooperationImage',
            key: 'cooperationImage',

            // @ts-ignore
            renderInput :() => (
                <Upload
                    listType="picture-card"
                    beforeUpload={( file) => {
                        return false;
                    }}
                    accept={".png, .jpg, .jpeg"}
                    onRemove={() => setCooperationImage(null)}
                    fileList={file2List}
                    onPreview={(e) => handlePreview(e, setPreview)}
                    onChange={(e) => handleFileListChange(setFile2List, setCooperationImage, e)}
                >
                    {file2List.length === 0 && uploadButton}
                </Upload>
            )
        }
    ])


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);

    const [form] = Form.useForm();
    const [formUpdate] = Form.useForm();

    function addColumns(){
        let newColumns: ColumnsType<T> = [];

        props.columnsToAdd?.forEach(c=>{
            if (c.show){
                newColumns.push(c);
            }
        });

        newColumns.push({
                title: 'Actions',
                key: 'actions',
                render: (value, record) => {

                    // @ts-ignore
                    const showVerify = "company" === props.entity && !record?.companyverified && record?.completedata;

                    return (
                        <Space size="middle">

                            {showVerify &&
                                // @ts-ignore
                                <Popconfirm
                                    placement="left"
                                    title="Issue Certificate"
                                    description={"Are you sure you want to verify the company and issue the certificate?"}
                                    // @ts-ignore
                                    onConfirm={() => verifyCompany(record.companyid, api, loadData)}
                                    onCancel={() => {}}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="primary">Issue Certificate</Button>
                                </Popconfirm>
                            }
                            <a onClick={() => onUpdate(record)}>Update</a>
                            <Popconfirm
                                title="Are you sure to delete this item?"
                                onConfirm={() => onDelete(record)}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="link">Delete</Button>
                            </Popconfirm>
                        </Space>
                    );
                },
            }
        )

        setDefaultColumns(newColumns);
    }

    const loadData = () => {

        let tokenToPost="thisIsAValidTokenButDontUseIt";
        let url = "";

        if (props.getUrl){
            url = props.getUrl;
            tokenToPost = getUserToken();
        }else{
            url = rootServiceURL() + `minimalgraph/rest/search/${capitalizeFirstLetter(props.entity)}`;
        }

        const criteriaItems = {
            criteriaItems: []
        };

        axios.post(url, criteriaItems, { headers: { token: tokenToPost } }).then(response => {
            if (response.status === 200) {
                console.log(response.data)
                setList(response.data);
            }
            else{
                if(props.getUrl && response.status === 401){
                    openNotification(api, 'error', 'top', `Session expired, log in again ${props.entity}`);
                }
                else {
                    openNotification(api, 'error', 'top', `An error has occurred loading ${props.entity}`);
                }
            }
        }).catch(reason => {
            openNotification(api, 'error', 'top', `An error has occurred loading ${props.entity} ${reason.response ? reason.response.data : reason}`);
        });
    };


    useEffect(() => {
        checkLogged(history, true);
        loadData();
        addColumns();
    }, []);


    const showModal = () => {
        setIsModalOpen(true);
    };

    function onFinish(values: any) {
        const entityValue: string = values[props.entity];
        const entityIdProperty = props.entity.toLowerCase() + "id";
        let postObject: any = {};
        postObject[entityIdProperty] = null;
        postObject[ props.entity] = entityValue;
        const url = rootServiceURL() + `minimalgraph/rest/${capitalizeFirstLetter(props.entity)}`;

        axios.post(url, postObject, { headers: { token: "thisIsAValidTokenButDontUseIt" } }).then(response => {
            if (response.status === 201) {
                setIsModalOpen(false);
                openNotification(api, 'success', 'top', `${props.entity} created`);
                loadData();
            } else {
                setIsModalOpen(false);
                openNotification(api, 'error', 'top', `An error has occurred creating ${props.entity}`);
            }
        }).catch(reason => {
            openNotification(api, 'error', 'top', `An error has occurred creating ${props.entity} ${reason.response ? reason.response.data : reason}`);
            setIsModalOpen(false);
        });
    }

    function onFinishFailed() {
        console.log(`Error creating ${props.entity}`)
    }

    const onUpdate = (entity: T) => {
        formUpdate.resetFields();
        // @ts-ignore
        setId(entity[props.entity+'id']);
        const updatedFieldValues: { [key: string]: any } = {};
        props.columnsToAdd?.forEach(field => {
            if(field.foreignkey){
                // @ts-ignore
                if(entity[field.key]){
                    // @ts-ignore
                    updatedFieldValues[field.key] = entity[field.key][field.key];
                    // @ts-ignore
                    formUpdate.setFieldsValue({ [field.key]: entity[field.key][field.key] });
                }
            }
            else{
                // @ts-ignore
                updatedFieldValues[field.key] = entity[field.key];
                // @ts-ignore
                formUpdate.setFieldsValue({ [field.key]: entity[field.key] });
            }

            if("company" === props.entity){
                const company = entity as unknown as ICompany;

                if(company.logoImage){
                    const logoImage = {
                        thumbUrl : "data:image/png;base64,"+ company.logoImage,
                        name: company.companyid+"_logo.png",
                        type: "image/png",
                        uid: '-1',
                        status: "done"
                    };
                    setFileList([logoImage])
                }

                if(company.cooperationImage){
                    const cooperationImage = {
                        thumbUrl : "data:image/png;base64,"+ company.cooperationImage,
                        name: company.companyid+"_cooperation.png",
                        type: "image/png",
                        uid: '-1',
                        status: "done"
                    };
                    setFile2List([cooperationImage])
                }
            }
        });
        setUpdateFieldValues(updatedFieldValues);
        setIsModalUpdateOpen(true);
    }
    function onFinishUpdate(values:any) {
        let entityUpdate: any;
        // @ts-ignore
        entityUpdate = list.find(data=>data[props.entity+'id']===id);
        if (entityUpdate){
            props.columnsToAdd?.filter(field=>field.update).forEach(field => {
                // @ts-ignore
                if (values[field.key] !== undefined) {
                    // @ts-ignore
                    if(field.foreignkey){
                        // @ts-ignore
                        entityUpdate[field.key] = {[field.key]: values[field.key]}
                    }
                    else{
                        // @ts-ignore
                        entityUpdate[field.key] = values[field.key];
                    }
                }
            });

            if("company" === props.entity){
                entityUpdate = {
                    ...entityUpdate,
                    logoImage: logoImage ? base64FromArrayBuffer(logoImage) : null,
                    cooperationImage: cooperationImage ? base64FromArrayBuffer(cooperationImage) : null
                }
            }

            const url = props.customUpdateUrl ? props.customUpdateUrl :  (rootServiceURL() + `minimalgraph/rest/${capitalizeFirstLetter(props.entity)}`);
            axios.put(url, entityUpdate, {headers: {token: "thisIsAValidTokenButDontUseIt"}}).then(response => {
                if(response.status == 204){
                    cleanFormUpdateData();
                    setIsModalUpdateOpen(false);
                    openNotification(api,'success', 'top', `${props.entity} updated`)
                    loadData();
                }
                else{
                    openNotification(api,'error', 'top', `An error has occurred updating ${props.entity}`);
                }

            }).catch(reason => {
                openNotification(api,'error', 'top', `An error has occurred updating ${props.entity} `+(reason.response ? reason.response.data : reason));
                setIsModalUpdateOpen(false);

            });
        }else{
            openNotification(api,'error', 'top', "An error has occurred updating ");
        }

        setIsModalUpdateOpen(false);
    }

    function onFinishFailedUpdate() {
        console.log(`Error updating ${props.entity}`)
    }
    const onDelete = (entity: any) =>{
        // @ts-ignore
        const entityToDeleteId:number = entity[`${props.entity}id`];

        const url = rootServiceURL() + `minimalgraph/rest/${capitalizeFirstLetter(props.entity)}/${entityToDeleteId}`;

        axios.delete(url, {headers: {token: "thisIsAValidTokenButDontUseIt"}}).then(response => {
            if(response.status == 204){
                loadData();
                openNotification(api,'success', 'top', `${props.entity} deleted`)

            }
            else{
                openNotification(api,'error', 'top', `An error has occurred deleting ${props.entity}`)
            }
        }).catch(reason => {
            openNotification(api,'error', 'top', `An error has occurred deleting ${props.entity} `+(reason.response ? reason.response.data : reason));
        });
    }

    function handleCancel() {
        setIsModalOpen(false);
    }
    function handleCancelUpdate(){
        cleanFormUpdateData();
        setIsModalUpdateOpen(false);
    }

    function cleanFormUpdateData(){
        formUpdate.resetFields();
        setFileList([]);
        setFile2List([]);
        setCooperationImage(null);
        setLogoImage(null);
    }
    // @ts-ignore
    const renderItem = (item: T) => {
        return (
            <List.Item>
                <List.Item.Meta
                    description={
                        <>
                            {props.columnsToAdd?.map((field) => (
                                field.show ? (
                                    <div key={field.key}>
                                        {/* @ts-ignore */}
                                        <p className={styles.titleList}>
                                            {/* @ts-ignore */}
                                            {field.title}: <span className={styles.subtitleList}>{typeof item[field.key as keyof T] === 'boolean'
                                            ? item[field.key as keyof T]
                                                ? ' Yes'
                                                : ' No'
                                            : item[field.key as keyof T] === null || item[field.key as keyof T] === undefined
                                                ? ' Empty'
                                                : ` ${item[field.key as keyof T]}`}</span>
                                        </p>
                                    </div>
                                ) : null
                            ))}
                            <Space size="middle" className={styles.titleList}>
                                Actions:
                                <a onClick={() => onUpdate(item)}>Update </a>
                                <a onClick={() => onDelete(item)}>Delete</a>
                            </Space>
                        </>
                    }
                />

            </List.Item>
        );
    };

    // @ts-ignore
    return (
        <>
            {contextHolder}
            {preview?.previewOpen &&
                <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: preview?.previewOpen,
                        onVisibleChange: (visible) => setPreview(null),
                        afterOpenChange: (visible) => !visible && setPreview(null),
                    }}
                    src={preview?.previewImage}
                />
            }
            <Row>
                <Col lg={0} xl={0} xxl={0} xs={24} sm={24} md={24}>
                    <AdminMobileMenu selectedKey={`${props.menu}`}></AdminMobileMenu>
                </Col>
            </Row>
            <Row>
                <Col lg={4} xl={4} xxl={4} xs={0} sm={0} md={0} >
                    <AdminMenu selectedKey={`${props.menu}`} ></AdminMenu>
                </Col>

                <Col lg={20} xl={20} xxl={20} xs={0} sm={0} md={0} className={styles.separationOnlyPadding}>
                    <Row style={{ alignItems: 'start', justifyContent: 'start', width: '100%', paddingBottom: 30}}>
                        <Col xs={{offset:0, span:23}} sm={{offset:0, span:23}} md={8} lg={8} xl={8} xxl={8}>
                            {"company" !== props.entity && "etradedesk" !== props.entity &&
                                <Button type="primary" onClick={showModal}>
                                    Create {capitalizeFirstLetter(props.entity)}
                                </Button>
                            }
                        </Col>
                        <Col xs={{offset:0, span:23}} sm={{offset:0, span:23}} md={8} lg={8} xl={8} xxl={8}>

                        </Col>
                        <Col xs={{offset:0, span:23}} sm={{offset:0, span:23}} md={8} lg={8} xl={8} xxl={8}  style={{alignItems: 'end', justifyContent: 'end',  display: "flex" }} >
                            <Button type="primary" onClick={()=> logOut(history)}>LOG OUT</Button>
                        </Col>
                    </Row>

                    <Modal
                        title={`Create ${capitalizeFirstLetter(props.entity)}`}
                        open={isModalOpen}
                        footer={null}
                        onCancel={handleCancel}>
                        <Form
                            name="basic"
                            form={form}
                            initialValues={{ remember: true }}
                            className={styles.form}
                            onFinish={(values) => onFinish(values)}
                            onFinishFailed={onFinishFailed}
                            scrollToFirstError
                            autoComplete="off">
                            <Form.Item
                                labelCol={{ span: 24 }}
                                label={`${props.entity}`}
                                name={`${props.entity}`}
                                rules={[{ required: true, message: `Please input your ${capitalizeFirstLetter(props.entity)}!` }]}>
                                <Input  className={styles.textInput}/>
                            </Form.Item>
                            <Button type="primary" htmlType="submit">
                                Create
                            </Button>
                        </Form>
                    </Modal>
                    <Modal title={`Update ${props.entity}`}
                           open={isModalUpdateOpen}
                           footer={null}
                           onCancel={handleCancelUpdate}>
                        <Form
                            name="basic"
                            form={formUpdate}
                            className={styles.form}
                            onFinish={(values) => onFinishUpdate(values)}
                            onFinishFailed={onFinishFailedUpdate}
                            scrollToFirstError
                            autoComplete="off">
                            {props.columnsToAdd?.filter(field =>(field.update)).map(field => (
                                <Form.Item
                                    labelCol={{ span: 24 }}
                                    label={""+field.title}
                                    name={field.key}
                                    rules={field.rules}>
                                    {field.renderInput ? field.renderInput(id) : <Input className={styles.textInput} />}
                                </Form.Item>
                            ))}

                            {"company" === props.entity && companyExtraColumns.map(field => (
                                <Form.Item
                                    labelCol={{ span: 24 }}
                                    label={""+field.title}
                                    name={field.key}
                                    rules={field.rules}>
                                    {field.renderInput ? field.renderInput(id) : <Input className={styles.textInput} />}
                                </Form.Item>
                            ))}
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                        </Form>
                    </Modal>
                    <Row>
                        <Col span={24}>
                            <PhocusTable columns={defaultColumns} dataSource={list} />
                        </Col>
                    </Row>
                </Col>
                <Col lg={0} xl={0} xxl={0} xs={18} sm={18} md={18} className={styles.separation}>
                    <Button type="primary" onClick={showModal}>
                        Create activity
                    </Button>
                    <PhocusList renderItem={renderItem} items={list}></PhocusList>
                </Col>
            </Row>
        </>
    );
}
export function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}