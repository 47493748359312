import {List} from "antd";
import React from 'react';

// @ts-ignore
export default function PhocusList({ items, renderItem }) {
    // @ts-ignore
    return (
        <List
            pagination={{ position: 'bottom', pageSize: 10 }}
            dataSource={items}
            size="large"
            renderItem={renderItem}/>
    );
}
