import React from 'react';
import {Col, Row} from 'antd';
import AdminMenu, {AdminMobileMenu} from "../AdminMenu";

export default function Users(){
    return (
        <>
            <Row>
                <Col lg={0} xl={0} xxl={0} xs={24} sm={24} md={24}>
                    <AdminMobileMenu selectedKey={'users'}></AdminMobileMenu>
                </Col>
            </Row>
            <Row>
                <Col lg={4} xl={4} xxl={4} xs={0} sm={0} md={0} >
                    <AdminMenu selectedKey={'users'}></AdminMenu>
                </Col>
                <Col span={18} style={{ padding: 20, marginTop:30, marginLeft:30 }}>
                    USERS
                </Col>
            </Row>
        </>
    );
}