import React, {useEffect, useState} from 'react';
import {Button, Col, message, Modal, notification, Row, Space, Table} from "antd";
import styles from './mycompany.module.css';


import '../../component/commonstyles/CommonStyle.css';
import {CheckOutlined, DownloadOutlined, FileTextOutlined, UploadOutlined} from "@ant-design/icons";
import {rootServiceURL} from "../../config";
import axios from "axios";
import {openNotification} from "../../util/OpenNotification";
import {ICompany} from "../../interfaces/Company";
import {
    base64FromArrayBuffer,
    companyProfilePhotoURL,
    copyTextToClipboard, downloadTicketPdf,
    logOut
} from "../../util/Utils";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {frontServer} from "../../config/Settings";
import classNames from "classnames";
import moment from "moment/moment";
import {Footer} from "../../component/footer/Footer";

export interface MyCompanyProps extends RouteComponentProps<{ companyId: string }> {
}

export default function MyCompany(props: MyCompanyProps) {
    // const [companyData, setCompanyData] = useState<null | ICompany>({
    //     address: "",
    //
    //     company: "",
    //     companyid: 0,
    //     companyverified: false,
    //     cooperationImage: "",
    //     domaincontrol: "",
    //     email: "",
    //     emailverified: false,
    //     establishedyear: 0,
    //     etradeDeskImage: "",
    //     etradedeskid: {etradedeskid: 0},
    //     etradeid: "",
    //     id: 0,
    //     mainactivity: "",
    //     members: [],
    //     name: "",
    //     oficialregistration: "",
    //     paymentid: "",
    //     blockchainconfirm: new Date(),
    //     blockchainhash: "undefined",
    //     categoryid: {id:1,  name: "", categoryid: 1, category: ""},
    //     certificatetypeid: {id: 1, name: "",  certificatetypeid: 1, certificatetype:""},
    //     countryid: {id: 1, name: "", countryid: 1, country: "pais"},
    //     createat:  new Date(),
    //     desknumber: 1111,
    //     idexpiration:  new Date(),
    //     idissued:  new Date(),
    //     logo: "undefined",
    //     pdfhash: "undefined",
    //     tax: "undefined",
    //
    //     phone: "",
    //     registereduserfirstname: "",
    //     registereduserlastname: "",
    //
    //     website: "",
    //     zipcode: ""
    // });
    const [companyData, setCompanyData] = useState<null | ICompany>(null);
    const [photoBytes, setPhotoBytes] = useState("/cpm.jpg");
    const [publicProfile, setPublicProfile] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const history = useHistory();

    const loadCompanyData = () => {
        let url = rootServiceURL() + "fullgraph/company/"
        let criteriaItems;
        if (props.match.params.companyId) {
            url = url + "getCompanyById/" + props.match.params.companyId;
            setPublicProfile(true)
        } else {
            const userData = localStorage.getItem('userData');
            if (userData != null) {
                url = url + "getCompanyByEmail/" + JSON.parse(userData).phocusUser.email;
            }
            setPublicProfile(false)
        }

        // @ts-ignore
        axios.get(url, {headers: {token: "thisIsAValidTokenButDontUseIt"}}).then(response => {
            if (response.status == 200) {
                if (response.data) {
                    setCompanyData(response.data);
                } else {
                    openNotification(api, 'error', 'top', "An error has occurred loading company data")
                }
            } else {
                openNotification(api, 'error', 'top', "An error has occurred loading company data")
            }
        }).catch(reason => {
            openNotification(api, 'error', 'top', "An error has occurred loading company data " + (reason.response ? reason.response.data : reason));
        });
    }

    const downloadPhoto = () => {
        const url = companyProfilePhotoURL(companyData);
        if (url) {
            // @ts-ignore
            axios.get(url, {
                headers: {
                    token: "thisIsAValidTokenButDontUseIt"
                },
                'responseType': 'arraybuffer'
            }).then(response => {
                if (response) {
                    setPhotoBytesFunc(response.data);
                } else {
                    setPhotoBytesFunc(null);
                }
            }).catch(reason => {
                setPhotoBytesFunc(null);
            })
        } else {
            setPhotoBytesFunc(null);
        }
    }

    const setPhotoBytesFunc = (arraybuffer: any) => {
        if (arraybuffer) {
            const base64data = base64FromArrayBuffer(arraybuffer);
            setPhotoBytes("data:image/png;base64," + base64data);
        } else {
            setPhotoBytes("/cpm.jpg");
        }
    }

    useEffect(() => {
        loadCompanyData();
    }, []);

    useEffect(() => {
        if (companyData && companyData.companyid) {
            downloadPhoto();
        }
    }, [companyData]);

    return (
        <>
            {contextHolder}
            <Row className={styles.bannerRow}>
                <Col xs={0} sm={0} md={0} lg={{span:4, offset:1}}
                     xl={{span:7, offset:1}}
                     xxl={{span:7, offset:1}} className={styles.imgMargin}>

                    <img className={styles.imgSize} src={"/e-trade-ID-verified.jpeg"}/>
                </Col>
                <Col xs={{span:8, offset:1}} sm={{span:6, offset:1}} md={{span:4, offset:1}} lg={0}
                     xl={0} xxl={0} className={styles.imgMargin}>
                    <img className={styles.imgSizeMobile} src={"/e-trade-ID-verified.jpeg"}/>
                </Col>

                <Col xs={{offset: 0, span: 0}} sm={{offset: 0, span: 0}} md={{offset: 0, span: 0}} lg={{offset: 0, span: 0}}
                     xl={{offset: 0, span: 8}} xxl={{offset: 0, span: 8}} style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                    {!publicProfile &&
                        <span className={classNames("poppinsBold", styles.company)} style={{marginTop: 55}}>MY COMPANY PROFILE</span>
                    }

                </Col>

                <Col xs={{offset: 6, span: 8}} sm={{offset: 6, span: 8}} md={{offset: 2, span: 8}} lg={{offset: 2, span: 12}}
                     xl={{offset: 2, span: 5}} xxl={{offset: 2, span: 5}} className={styles.logout}>
                    {!publicProfile && <Button type="primary" className={classNames(styles.logoutButton, "poppinsLight")} onClick={() => logOut(history)}>LOG OUT</Button>}
                </Col>

            </Row>
            {companyData != null &&
                <>
                    <div className={styles.borderTop}/>
                    <Row className={styles.content}>
                        <Col className={styles.smallBorderTop}  xs={{span:22, offset:1}} sm={{span:22, offset:1}} md={{span:22, offset:1}}
                             lg={{span:13, offset:1}} xl={{span:13, offset:2}} xxl={{span:13, offset:2}}>
                            <Row style={{marginTop:30}}>
                                <Col xxl={{span:4, offset:1}} xl={{span:6, offset:1}} lg={{span:8, offset:1}} md={{span:8, offset:1}} sm={{span:10, offset:1}} xs={{span:18, offset:3}}
                                >
                                    <img  className={styles.profileImg} src={photoBytes}/>
                                </Col>
                                <Col xxl={{span: 17, offset: 1}} xl={{span: 16, offset: 1}} lg={{span: 14, offset: 1}}
                                     xs={{span: 18, offset: 3}} md={{span: 10, offset: 1}} sm={{span: 12, offset: 1}}
                                     className={styles.tenMarginTop}>

                                    <p>
                                        {companyData && (
                                            <span  className={classNames("poppinsBold", styles.category)}>
                                        {companyData.categoryid == null ? 'empty' : companyData.categoryid.category}
                                    </span>
                                        )}
                                    </p>
                                    <p>
                                        {companyData && (
                                            <span className={classNames("poppinsBold", styles.company)}
                                                  >
                                        {companyData.company == null ? 'Empty' : companyData.company}
                                    </span>
                                        )}
                                    </p>
                                    {companyData && (
                                        <Row >
                                            <Col style={{marginTop:10, marginRight:-70}} xxl={{span:8, offset:0}} xl={{span:24, offset:0}} lg={{span:24, offset:0}} md={{span:24, offset:0}} sm={{span:24, offset:0}} xs={{span:24, offset:0}}>
                                                <img className={styles.countryImg} src={"/spain.png"}/>
                                                <span className={classNames("poppinsLight",styles.address)}>
                                                     {companyData.countryid == null ? 'Empty' : companyData.countryid.country}
                                                </span>
                                            </Col>
                                            <Col style={{marginTop:10, marginRight:-70}} xxl={{span:8, offset:0}} xl={{span:24, offset:0}} lg={{span:24, offset:0}} md={{span:24, offset:0}} sm={{span:24, offset:0}} xs={{span:24, offset:0}}>
                                                <img className={styles.countryImg} src={"/calendar.svg"}/>

                                                <span className={classNames("poppinsLight",styles.address)}>
                                                    Stablished in {companyData.establishedyear == null ? 'Empty' : companyData.establishedyear}
                                                </span>
                                            </Col>
                                            <Col style={{marginTop:10}} xxl={{span:8, offset:0}} xl={{span:24, offset:0}} lg={{span:24, offset:0}} md={{span:24, offset:0}} sm={{span:24, offset:0}} xs={{span:24, offset:0}}>
                                                <img className={styles.countryImg} src={"/mail.svg"}/>

                                                <span className={classNames("poppinsLight",styles.address)}>
                                                   {companyData.email == null ? 'Empty' : companyData.email}
                                                </span>
                                            </Col>

                                        </Row>

                                    )}
                                </Col>
                            </Row>
                            <div className={styles.contentBorder}>
                                <Row justify="center" className={styles.certificateSpace}>
                                    <Col lg={24} xs={{span: 24}} md={{span: 24}} sm={{span: 24}} className={styles.zeroMarginBottom}>
                                        <p className={styles.certificateText}>
                                            <span className={classNames("poppinsBold",styles.securedCertificate)} >
                                                This certificate is secured
                                            </span>
                                            <br/>
                                            <span className={classNames("poppinsLight",styles.securedCertificateSecundary)} >
                                                by WTPF Blockchain Secure Network
                                            </span>
                                            <br/>
                                            {companyData.companyverified &&
                                                <Button icon={<FileTextOutlined />} size={"large"} className={styles.downloadButton}
                                                        onClick={() => downloadTicketPdf(companyData?.companyid, api)}
                                                >Download PDF</Button>
                                            }
                                        </p>
                                        <p className={styles.separationP}>

                                            <span className={classNames("robotoLight", styles.id)} >
                                                ID Number:
                                            </span>
                                            <span style={{marginLeft:5}} className={classNames("robotoLight", styles.idNumber)} >
                                                {companyData.etradeid}
                                            </span>

                                        </p>
                                    </Col>
                                </Row>
                                <Row justify="center">
                                    <Col className={styles.verifiedSeparation}>
                                        <p className={classNames("poppinsBold",styles.verifiedText)}>
                                            {companyData?.companyverified ? "Verified Details" : "Verify Pending"}
                                        </p>
                                    </Col>
                                </Row>
                                <Row justify="center" >
                                    <Col lg={24} xs={24} sm={24} md={24}
                                        className={styles.greyColum}>
                                        <Row className={styles.borderBetweenContent}>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Company Name</p>
                                            </Col>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                {companyData && (
                                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                                        {companyData.company == null ? 'Empty' : companyData.company}
                                                    </p>
                                                )}
                                            </Col>
                                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify="center" className={styles.zeroMarginTop}>
                                    <Col lg={24} xs={24} sm={24} md={24} className={styles.zeroMarginBottom}>
                                        <Row className={styles.borderBetweenContent}>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Oficial
                                                    Registration</p>
                                            </Col>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                {companyData && (
                                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                                        {companyData.oficialregistration == null ? 'Empty' : companyData.oficialregistration}
                                                    </p>
                                                )}
                                            </Col>
                                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify="center" className={styles.zeroMarginTop}>
                                    <Col lg={24} xs={24} sm={24} md={24}
                                         className={classNames(styles.zeroMarginBottom, styles.colorRow, styles.greyColum)}>
                                        <Row className={styles.borderBetweenContent}>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Tax Number</p>
                                            </Col>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                {companyData && (
                                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                                        {companyData.tax == null ? 'Empty' : companyData.tax}
                                                    </p>
                                                )}
                                            </Col>
                                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify="center" className={styles.zeroMarginTop}>
                                    <Col lg={24} xs={24} sm={24} md={24} className={styles.zeroMarginBottom}>
                                        <Row className={styles.borderBetweenContent}>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Location</p>
                                            </Col>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                {companyData && (
                                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                                        {companyData.address == null ? 'Empty' : companyData.address}
                                                    </p>
                                                )}
                                            </Col>
                                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify="center" className={styles.zeroMarginTop}>
                                    <Col lg={24} xs={24} sm={24} md={24}
                                         className={classNames(styles.zeroMarginBottom, styles.colorRow)}
                                         >
                                        <Row className={styles.borderBetweenContent}>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Main Activity</p>
                                            </Col>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                {companyData && (
                                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                                        {companyData.mainactivity}
                                                    </p>
                                                )}
                                            </Col>
                                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify="center" className={styles.zeroMarginTop}>
                                    <Col lg={24} xs={24} sm={24} md={24} className={classNames(styles.zeroMarginBottom)}>
                                        <Row className={styles.borderBetweenContent}>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Website</p>
                                            </Col>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                {companyData && (
                                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                                        {companyData.website == null ? 'Empty' : companyData.website}
                                                    </p>
                                                )}
                                            </Col>
                                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify="center" className={styles.zeroMarginTop}>
                                    <Col lg={24} xs={24} sm={24} md={24} className={classNames(styles.zeroMarginBottom, styles.colorRow)}>

                                        <Row className={styles.borderBetweenContent}>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Domain Control</p>
                                            </Col>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                {companyData && (
                                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                                        {companyData.domaincontrol == null ? 'Empty' : companyData.domaincontrol}
                                                    </p>
                                                )}
                                            </Col>
                                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify="center" className={styles.zeroMarginTop}>
                                    <Col lg={24} xs={24} sm={24} md={24} className={classNames(styles.zeroMarginBottom)}>
                                        <Row className={styles.borderBetweenContent}>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Certificate
                                                    Type</p>
                                            </Col>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                {companyData && (
                                                    <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                                        {companyData.certificatetypeid == null ? 'Empty' : companyData.certificatetypeid.certificatetype}
                                                    </p>
                                                )}
                                            </Col>
                                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify="center" className={styles.zeroMarginTop}>
                                    <Col lg={24} xs={24} sm={24} md={24} className={classNames(styles.zeroMarginBottom, styles.colorRow)}>

                                        <Row className={styles.borderBetweenContent}>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>ID issued</p>
                                            </Col>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                                    {companyData?.idissued ? moment(companyData?.idissued).format("DD-MM-YYYY") : ""}
                                                </p>
                                            </Col>
                                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify="center" className={styles.zeroMarginTop}>
                                    <Col lg={24} xs={24} sm={24} md={24} className={classNames(styles.zeroMarginBottom)}>
                                        <Row className={styles.borderBetweenContent}>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>ID Expiration</p>
                                            </Col>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                                    {companyData?.idexpiration ? moment(companyData?.idexpiration).format("DD-MM-YYYY") : ""}
                                                </p>
                                            </Col>
                                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify="center" className={styles.zeroMarginTop}>
                                    <Col lg={24} xs={24} sm={24} md={24} className={classNames(styles.zeroMarginBottom, styles.colorRow)}>

                                        <Row className={styles.borderBetweenContent}>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p className={["robotoLight", 'left-row', styles.titleField].join(' ')}>Blockchain hash</p>
                                            </Col>
                                            <Col offset={1} lg={8} xs={8} sm={8} md={8}>
                                                <p style={{paddingBottom:10, overflowWrap: "anywhere"}} className={["robotoLight", 'right-row', styles.dataField].join(' ')}>
                                                    {companyData?.blockchainhash ? <a onClick={() => window.open("https://etherscan.io/tx/" + companyData?.blockchainhash, "_blank")}
                                                        >{companyData.pdfhash}</a>
                                                        : <span>{companyData.pdfhash}</span>}
                                                </p>
                                            </Col>
                                            <Col offset={4} lg={2} xs={{span: 2, offset: 3}} sm={{span: 2, offset: 3}}
                                                 md={{span: 2, offset: 3}} className={styles.iconCol}>
                                                <img className={styles.checkIcon} src={'/check.svg'} alt="Verified Icon" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>

                            {!publicProfile && companyData?.companyverified && <>

                                <Row justify="center" className={styles.centerFull}>
                                    <Col xxl={16} xl={16} lg={16} xs={23} sm={23} md={23} className={styles.tenMarginTop}>
                                        <Button onClick={() => {
                                            copyTextToClipboard("<a " +
                                                "  target=\"popup\" \n" +
                                                "  onclick=\"window.open('" +frontServer +"companyCertificate/" + companyData.companyid + "','popup','width=670,height=950,left=100,top=50'); return false;\">\n" +
                                                "    <img width='200' height='81' src=\"https://id.etotrust.com/banner200-81.png\"> " +
                                                "</a>").then(value => {
                                                openNotification(api, 'success', 'top', "HTML certificate successfully copied to clipboard");
                                            }).catch(reason => {
                                                console.error("Error copy certificate html to clipboard ", reason)
                                                openNotification(api, 'error', 'top', "An error has occurred loading categories " + (reason.response ? reason.response.data : reason));
                                            })
                                        }
                                        }  className={classNames("poppinsBold",styles.defaultButton)} type="primary">
                                            Copy certificate HTML to clipboard</Button>
                                    </Col>
                                </Row>
                                <Row justify="center" className={classNames(styles.centerFull, styles.tenMarginTop)}>
                                    <Col lg={20} xs={20} sm={20} md={20}>
                                        <p className={classNames("poppinsLight", styles.informationText)}>
                                            Contact your web master or your IT support, to include the ID certificate on your website. This action will take just a few minutes.
                                        </p>

                                    </Col>
                                </Row>
                            </>
                            }
                            <Row justify="center" className={classNames(styles.centerFull, styles.fiftyTopMargin)}>
                                <Col md={5}>
                                    <img width={'200px'} src={"/e-credentials.svg"}/>
                                </Col>
                            </Row>

                            <Row justify="center" className={styles.centerFull}>
                                <Col lg={20} xs={20} sm={20} md={20} xl={20} xxl={20} className={styles.thirtyTopMargin}>
                                    <p className={classNames("poppinsLight", styles.informationText)}>
                                        The e-Credentials program collects and validates information, background and
                                        references that companies add to their e-trade ID to enhance their credibility.
                                        <br/>
                                        Enter and access certifications, documents, and testimonials from clients of
                                        this company
                                    </p>


                                </Col>
                            </Row>
                            <Row justify="center" className={classNames(styles.centerFull)}>
                                <Col xxl={8} xl={8} lg={10} xs={20} sm={20} md={20} className={styles.tenMarginTop}>
                                    <Button onClick={()=>{
                                        alert('Not available');}}
                                            type="primary" className={classNames(styles.defaultButton, "poppinsLight")}>
                                        ACCESS
                                    </Button>
                                </Col>
                            </Row>
                            <Row justify="center" className={classNames(styles.centerFull, styles.tenMarginTop)}>
                                <Col lg={20} xs={20} sm={20} md={20}>
                                    <p className={classNames("poppinsLight", styles.informationText)}>
                                        Soon available for those users who have an e-trade ID certificate
                                    </p>

                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{span:22,offset:1}} sm={{span:22,offset:1}} md={{span:22,offset:1}} lg={{span:8, offset:1}} xl={{span:7, offset:1}} xxl={{span:7, offset:1}}>
                            <div className={styles.smallBorderTop}>
                                <Row justify="start" className={styles.twentyTopMargin} style={{marginLeft:15}}>
                                    <Col  >
                                        <img width={180} src={"/verified-company-by.svg"}/>
                                    </Col>
                                </Row>
                                <Row justify="center" className={styles.twentyTopMargin}>
                                    <Col >
                                        <img className={styles.rightColImg} src={"/e-trade-desk-verified.svg"}/>
                                    </Col>
                                </Row>

                                <Row justify="start" className={styles.twentyTopMargin}>
                                    <Col offset={1} lg={22} xs={22} sm={22} md={22}
                                         className={styles.blackBorderBottom}>
                                        <p className={classNames("poppinsBold", styles.rightColText)}>
                                            This e-trade desk is operated by:
                                        </p>
                                    </Col>
                                </Row>
                                <Row justify="center" className={styles.tenMarginTop}>
                                    <Col >
                                        <img className={styles.rightColImg} src={"data:image/png;base64," +companyData.etradeDeskImage}/>
                                    </Col>
                                </Row>

                                <Row justify="start" className={styles.twentyTopMargin}>
                                    <Col offset={1} lg={22} xs={22} sm={22} md={22}
                                         className={styles.blackBorderBottom}>
                                        <p className={classNames("poppinsBold",styles.rightColText)}>
                                            People in this hosting institution:
                                        </p>
                                    </Col>
                                </Row>
                                <Row justify="start" className={styles.tenMarginTop}>
                                    <Col offset={1} lg={22} xs={22} sm={22} md={22}>
                                        <p className={classNames("poppinsLight", styles.job)}>
                                            Contact them for additional information.
                                        </p>

                                    </Col>
                                </Row>
                                <Row justify="start" className={styles.twentyTopMargin}>
                                    {companyData && companyData.members && companyData.members.length > 0  && companyData.members[0]
                                        &&
                                        <>
                                        <Col offset={1} xxl={5} xl={5} lg={5} xs={0} sm={0} md={0} >
                                            <img width={'100%'} style={{maxWidth:75, borderRadius:50}} src={"data:image/png;base64," +companyData.members[0].image}/>
                                        </Col>
                                        <Col lg={{span:10, offset:2}} xl={{span:15, offset:2}} xxl={{span:10, offset:2}} xs={0} sm={0} md={0}>
                                            <p className={classNames("poppinsBold", styles.firstName)}> {companyData.members[0].name} </p>
                                            <p className={classNames("poppinsLight", styles.job)}> {companyData.members[0].rol}</p>
                                            <p className={classNames("poppinsLight", styles.email)}> {companyData.members[0].email}</p>
                                        </Col>
                                        </>
                                    }
                                </Row>
                                <Row justify="center" className={styles.twentyTopMargin}>
                                    {companyData && companyData.members && companyData.members.length > 0  && companyData.members[0]
                                        &&
                                        <>
                                            <Col offset={1} xxl={0} xl={0} lg={0} xs={5} sm={5} md={5} >
                                                <img width={'100%'} style={{maxWidth:75, borderRadius:50}} src={"data:image/png;base64," +companyData.members[0].image}/>
                                            </Col>
                                            <Col lg={0} xl={0} xxl={0} xs={23} sm={23} md={23}>
                                                <p className={classNames("poppinsBold", styles.firstName)}> {companyData.members[0].name} </p>
                                                <p className={classNames("poppinsLight", styles.job)}> {companyData.members[0].rol}</p>
                                                <p className={classNames("poppinsLight", styles.email)}> {companyData.members[0].email}</p>
                                            </Col>
                                        </>
                                    }

                                </Row>

                                <Row justify="start" className={styles.tenMarginTop}>
                                    {companyData && companyData.members && companyData.members.length >= 1  && companyData.members[1]
                                        &&
                                        <>
                                            <Col offset={1} xxl={5} xl={5} lg={5} xs={0} sm={0} md={0} >
                                                <img width={'100%'} style={{maxWidth:75, borderRadius:50}}  src={"data:image/png;base64," +companyData.members[1].image}/>
                                            </Col>
                                            <Col lg={{span:10, offset:2}} xl={{span:15, offset:2}} xxl={{span:10, offset:2}} xs={0} sm={0} md={0}>
                                                <p className={classNames("poppinsBold", styles.firstName)}> {companyData.members[1].name}</p>
                                                <p className={classNames("poppinsLight", styles.job)}> {companyData.members[1].rol}</p>
                                                <p className={classNames("poppinsLight", styles.email)}> {companyData.members[1].email}</p>
                                            </Col>
                                        </>
                                    }

                                </Row>
                                <Row justify="center" className={styles.tenMarginTop}>
                                    {companyData && companyData.members && companyData.members.length >= 1  && companyData.members[1]
                                        &&
                                        <>
                                            <Col offset={1} xxl={0} xl={0} lg={0} xs={5} sm={5} md={5} >
                                                <img width={'100%'} style={{maxWidth:75, borderRadius:50}}  src={"data:image/png;base64," +companyData.members[1].image}/>
                                            </Col>
                                            <Col lg={0} xl={0} xxl={0} xs={23} sm={23} md={23}>
                                                <p className={classNames("poppinsBold", styles.firstName)}> {companyData.members[1].name}</p>
                                                <p className={classNames("poppinsLight", styles.job)}> {companyData.members[1].rol}</p>
                                                <p className={classNames("poppinsLight", styles.email)}> {companyData.members[1].email}</p>
                                            </Col>
                                        </>
                                    }
                                </Row>

                                {companyData.cooperationImage &&
                                    <>
                                        <Row justify="start" className={styles.twentyTopMargin}>
                                            <Col offset={1} lg={22} xs={22} sm={22} md={22}
                                                 className={styles.blackBorderBottom}>
                                                <p className={classNames("poppinsBold", styles.rightColText)}>
                                                    In cooperation with:
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row justify="center"  style={{marginBottom:20, marginTop:20}}>
                                            <Col>
                                                <img className={styles.rightColImg} src={"data:image/png;base64," +companyData.cooperationImage}/>
                                            </Col>
                                        </Row>
                                        <Row justify="start" className={styles.twentyTopMargin}>
                                            <Col offset={1} lg={22} xs={22} sm={22} md={22}
                                                 className={styles.blackBorderBottom}>
                                                <p className={classNames("poppinsBold", styles.rightColText)}></p>
                                            </Col>
                                        </Row>
                                    </>
                                }

                                <Row justify="center" className={styles.twentyTopMargin} style={{marginBottom:20}}>
                                    <Col >
                                        <img className={styles.rightColLargeImg} src={"/key_banner.jpeg"}/>
                                    </Col>
                                </Row>
                                <Row justify="center" className={styles.twentyTopMargin} style={{marginBottom:20}}>
                                    <Col>
                                        <img className={styles.rightColLargeImg} src={"/global_banner.jpeg"}/>
                                    </Col>
                                </Row>
                            </div>

                        </Col>
                    </Row>
                </>
            }

            <Footer/>
        </>
    );
}




