import {Col, Row} from "antd";
import styles from "./Footer.module.css";
import classNames from "classnames";
import React from "react";

const fontSizeClass = classNames({
    'default-font-size': true,
    'mobile-font-size': window.innerWidth <= 768,
});
export function Footer() {
    return <Row align="middle" className={styles.footerRow}>
        <Col xs={24} sm={24} md={24} lg={7} offset={1} className={styles.tenTopMargin}>
            <img className={styles.footerImg} src={"/e-trade-title-white.png"}/>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} offset={1} style={{marginTop: 10}}>
            <p className={classNames(fontSizeClass, styles.footerText)}>
                © 2023 WTPF | All Rights Reserved
                | <a href={"https://lawwwing.com/documents/9414325e-8c53-4b03-b84b-1d79a2bcbe81/privacy-policy/en/"} target="_blank" style={{color:'white'}}>Privacy
                Policy and Cookies</a>
                | <a href={"https://lawwwing.com/documents/9414325e-8c53-4b03-b84b-1d79a2bcbe81/legal-notice/en/"} target="_blank" style={{color:'white'}}>Legal
                Notice</a>
                | <a
                href={"https://lawwwing.com/documents/9414325e-8c53-4b03-b84b-1d79a2bcbe81/terms-and-conditions/en/"} target="_blank" style={{color:'white'}}>Terms
                & Conditions of Purchase</a>
            </p>
        </Col>
        <Col xs={0} sm={0} md={3} lg={2}>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} offset={1} className={styles.tenTopMargin}>
            <img className={styles.footerImgFederation} src={"/e-trade-federation.png"} alt={"e-trade-federation.png"}/>
        </Col>
    </Row>;
}