import React, {useState} from 'react';
import styles from './forgotpassword.module.css'
import {Button, Col, Form, Input, notification, Row} from 'antd';
import classNames from 'classnames';
import {openNotification} from "../../util/OpenNotification";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {rootServiceURL} from "../../config";
import {Footer} from "../../component/footer/Footer";

const fontSizeClass = classNames({
    'default-font-size': true,
    'mobile-font-size': window.innerWidth <= 768,
});
function ForgotPassword(){
    const [api, contextHolder] = notification.useNotification();
    const [working, setWorking] = useState(false);
    const history = useHistory();

    const onFinish = (values: any) => {
        const url = rootServiceURL() + "minimalgraph/phocusservice/forgotPassword"
        try {
            setWorking(true)
            axios.post(url, values.user.email, {headers: {'Content-Type': 'text/plain'}}).then(value => {
                console.log("value", value)
                if(value.status === 200){
                    console.log("value", value)
                    openNotification(api, 'success', 'top', "Check your email to reset your password");
                }
                else{
                    openNotification(api, 'error', 'top', 'Forgot password error');
                }
                setWorking(false)
            }).catch(reason => {
                setWorking(false)
                console.log("reason", reason)
                openNotification(api, 'error', 'top', 'Forgot password error: '+(reason.response ? reason.response.data : reason));
            });
        } catch (error) {
            setWorking(false)
            console.error('Error en la solicitud:', error);
        }
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div>
            {contextHolder}
            <Row justify="start" align="middle">
                <Col xs={24} sm={24} md={14} lg={14} xl={14}  className={styles.contentStyle}>
                    <img  src={"/hexagone.png"} />
                </Col>
                <Col  xs={24} sm={24} md={10} lg={10}  xl={10} className={styles.formStyle}>
                    <Row justify="center" align="middle">
                        <img  width={'200px'} src={"/e-trade-title.png"} className={styles.imgMargin} />
                    </Row>
                    <Row className={styles.tenMargin} justify="center" align="middle">
                        <h1 className={styles.titleForgotPassword}>FORGOT PASSWORD</h1>
                    </Row>

                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={(values) => onFinish(values)}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off">
                            <Row className={styles.formMargin} justify="center" align="middle">
                                <Col xs={16}>
                                    <Form.Item
                                        name={['user', 'email']}
                                        label=""
                                        rules={[{ type: 'email', required: true, message: 'Please input your email' }]}>
                                        <Input placeholder={"Email Address"}  className={styles.emailInput}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row className={styles.tenMargin} justify="center" align="middle">
                                <Col xs={16}>
                                    <Form.Item>
                                        <Button disabled={working} className={styles.fullWidth}  type="primary" htmlType="submit">
                                            SEND EMAIL
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="center" align="middle">
                                <Col xs={16}>
                                    <Button disabled={working} className={classNames(styles.linkButton, styles.fullWidth)}  onClick={() => history.push("/login")} type="link">
                                        GO TO LOGIN
                                    </Button>
                                </Col>
                            </Row>

                        </Form>


                </Col>
            </Row>
            <Footer/>
        </div>
    );
}
export default ForgotPassword;