export const serverName = "id.etotrust.com:8443";

// export const serverName = "localhost:8080";
// export const serverName = "id.etotrust.com:8080";

export const server = "https://" + serverName + "/";
export const frontServer = "https://" + window.location.hostname + "/";

export const CONFIG = {
    firstScreen: "CustomerLandingpage",
    restService: {
        restLoginUrl: server + "phocus-kpi/fullgraph/phocusservice/login",
        restRegistrationUrl: server + "phocus-kpi/fullgraph/phocusservice/register",
        restServiceRoot: server + "etrade/",
        kpiUrl: server + "phocus-kpi/",
        uiService: "minimalgraph/ui/",
        dataService: "minimalgraph/rest/",
        completeGraphDataService: "completegraph/rest/",
        fullGraphDataService: "fullgraph/rest/",
        uploadFileService: "completegraph/fileservice/",
        reportService: "completegraph/reportingService/",
        ws: "ws://" + serverName + "/LEX/phocus",
        wsKpi: "ws://" + serverName + "/phocus-kpi/phocus",
    },
}

export const CUSTOM_SERVICES_FOR_ENTITIES = {}

export const CUSTOM_SERVICES_FOR_REPORTS = {}

export const CUSTOM_FIELD_RENDERERS = {}