import {Md5} from 'ts-md5';
import {rootServiceURL} from "../config";
import moment from "moment/moment";
import fileDownload from "js-file-download";
import axios from "axios";
import {openNotification} from "./OpenNotification";

export const encryptMD5 = (text) => {
    return Md5.hashStr(text);
}

export const checkLogged = (history, adminRequired) => {
    const userData = localStorage.getItem('userData');

    if(userData==null){
        history.push("/login");
    }
    else{
        console.log("userDate", JSON.parse(userData))
        if(adminRequired){
            if(!JSON.parse(userData).phocusUser?.roles?.some(role => role.roleid?.role === "System administrator" || role.roleid?.role === "Administrator" ||
                role.roleid?.role === "Etrade Desk")){
                alert("You must be an administrator user to view this page")
                history.push("/login");
            }
        }
    }
}
export function isEtradeDeskRol() {
    const userLogged = getUserLogged();
    return isEtradeDeskRolByLoggedUser(userLogged);
}

export function isEtradeDeskRolByLoggedUser(userLogged) {
    if(userLogged && userLogged.roles){
        for (const role of userLogged.roles) {
            if (role.roleid.roleid === 2) {
                return true;
            }
        }
    }

    return false; // Devuelve false si ningún rol tiene roleid igual a 2
}

export function isEtradeDeskOrAdminByLoggedUser(userLogged) {
    if(userLogged && userLogged.roles){
        for (const role of userLogged.roles) {
            if (role.roleid.roleid === 2 || role.roleid.roleid === 1 || role.roleid.roleid === 3) {
                return true;
            }
        }
    }

    return false; // Devuelve false si ningún rol tiene roleid igual a 2
}

export const getUserLogged = () => {
    const userData = localStorage.getItem('userData');

    if(userData==null){
        return null;
    }
    else{
        return JSON.parse(userData).phocusUser;
    }
}
export const getUserToken = () => {
    const userData = localStorage.getItem('userData');

    if(userData==null){
        return null;
    }
    else{
        return JSON.parse(userData).token;
    }
}
export const logOut = (history) => {
    localStorage.removeItem('userData');
    history.push("/login");
}

export function getNormalizedText(value) {
    return value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

export function filterSelectOptions(){
    return (inputValue, option) => (getNormalizedText(option?.label ?? '')).toLowerCase().includes(getNormalizedText(inputValue))
}

export const companyProfilePhotoURL = (companyData, extension) => {
    if (companyData && companyData.companyid) {
        return rootServiceURL() + "minimalgraph/fileservice/file/company/" + companyData.companyid + "." + (extension || "png");
    }
}

export const binaryStringFromBinaryArray = binaryArray => {
    const string = new Uint8Array(binaryArray).reduce((data, byte) => data + String.fromCharCode(byte), '');
    return string;
}

export const base64FromArrayBuffer = arrayBuffer => {
    return btoa(binaryStringFromBinaryArray(arrayBuffer));
}

export const fileToBytes = (file) => {
    return new Promise(function(resolve,reject){
        const filename = file.name;
        const asByteArrayReader = new FileReader();
        asByteArrayReader.onload = e => {
            console.log("e", e)
            resolve(e.target.result)
        };
        asByteArrayReader.onerror = (e) => {
            console.error("Error ",e)
            reject(asByteArrayReader);
        };
        asByteArrayReader.readAsArrayBuffer(file);
    });
};

export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
        console.log("clipboard ",text)
        return await navigator.clipboard.writeText(text);
    } else {

        // Use the 'out of viewport hidden text area' trick
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Move textarea out of the viewport so it's not visible
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
            console.log("excecCommand ",text)
            return document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
    }
}

export function getDateRender() {
    return (record) => {
        return (
            <div>
                <p>{record ? moment(record).format("DD-MM-YYYY HH:mm") : ""}</p>
            </div>
        );
    };
}

export function downloadTicketPdf(companyId, api){
    let url = rootServiceURL() + "completegraph/company/downloadPDF/"+companyId;

    axios.get(url, {responseType: 'arraybuffer'}).then(response => {
        if(response.status == 200){
            if(response.data){
                fileDownload(response.data,"company-certificate.pdf")
            }
            else{
                openNotification(api,'error', 'top', "An error has occurred downloading company certificate")
            }
        }
        else{
            openNotification(api,'error', 'top', "An error has occurred downloading company certificate")
        }
    }).catch(reason => {
        openNotification(api,'error', 'top', "An error has occurred downloading company certificate "+(reason.response ? reason.response.data : reason));
    });
}