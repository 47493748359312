import React, { useState } from 'react';
import { Modal } from 'antd';
import {MailOutlined} from "@ant-design/icons";
// @ts-ignore
import commonStyles from '../../component/commonstyles/CommonStyle.css';
import styles from './ConfirmEmailModal.module.css'
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

export default function ConfirmEmailModal() {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const history = useHistory();


    const handleOk = () => {
        setIsModalOpen(false);
        history.push('/login');
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const titleWithIcon = (
        <div className={classNames(commonStyles.poppinsBold, styles.titleModal)} >
            <MailOutlined className={styles.icon} />
            Confirm your email
        </div>
    );
    return (
        <>
            <Modal title={titleWithIcon}
                   open={isModalOpen}
                   onOk={handleOk}
                   closable={false}
                   maskClosable={false}
                   cancelButtonProps={{
                       className: styles.cancel,
                   }}>
                <p className={classNames(commonStyles.poppinsLight, styles.subtitleModal)} >
                    Confirm your account by clicking the link in the email we sent to your account.
                </p>
            </Modal>
        </>
    );
};