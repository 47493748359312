import React from "react";

import './App.css';
import AppLogin from './pages/login/Login';
import LoginSmsCode from './pages/login/LoginSmsCode'

import Register from "./pages/register/Register";
import CompleteData from "./pages/completedata/App";
import ConfirmEmail from "./pages/confirmemail/ConfirmEmail";

import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import Companies from "./pages/admin/companies/Companies";
import Countries from "./pages/admin/countries/Countries";
import Categories from "./pages/admin/categories/Categories";
import Activities from "./pages/admin/activities/Activities";
import CertificateTypes from "./pages/admin/certificatetypes/CertificateTypes";
import Taxes from "./pages/admin/taxes/Taxes";
import Users from "./pages/admin/users/Users";
import MyCompany from "./pages/mycompany/MyCompany";
import CompanyCertificate from "./pages/companyCertificate/CompanyCertificate";
import ForgotPassword from "./pages/forgotpassword/ForgotPassword";
import ResetPassword from "./pages/resetpassword/ResetPassword";
import ConfirmEmailModal from "./pages/confirmemailmodal/App";
import CreateCompany from "./pages/admin/createCompany/CreateCompany";
import CreateEtradeDesk from "./pages/admin/createEtradeDesk/CreateEtradeDesk";
import EtradeDesk from "./pages/admin/etradedesk/EtradeDesk";
import LogOut from "./pages/admin/logout/LogOut";
import CompaniesToFix from "./pages/admin/companiesToFix/CompaniesToFix";

function App() {
  return (
      <Router>
          <div>
            <Switch>
                <Route path="/confirmEmail/:cipherEmail" component={ConfirmEmail}/>
                <Route exact path="/" component={AppLogin}/>
                <Route exact path="/login" component={AppLogin}/>
                <Route exact path="/loginSmsCode" component={LoginSmsCode}/>
                <Route exact path="/forgotPassword" component={ForgotPassword}/>
                <Route exact path="/resetPassword/:token" component={ResetPassword}/>
                <Route exact path="/register" component={Register}/>
                <Route exact path="/completedata" component={CompleteData}/>
                <Route exact path="/confirmEmailModal" component={ConfirmEmailModal}/>
                <Route exact path="/admin" component={CreateCompany}/>
                <Route exact path="/myCompany" component={MyCompany}/>
                <Route path="/myCompany/:companyId" component={MyCompany}/>
                <Route exact path="/admin/companies" component={Companies}/>
                <Route exact path="/admin/companiesToFix" component={CompaniesToFix}/>
                <Route exact path="/admin/createCompany" component={CreateCompany}/>
                <Route exact path="/admin/createEtradeDesk" component={CreateEtradeDesk}/>
                <Route exact path="/admin/etradedesk" component={EtradeDesk}/>
                <Route exact path="/admin/countries" component={Countries}/>
                <Route exact path="/admin/categories" component={Categories}/>
                <Route exact path="/admin/activities" component={Activities}/>
                <Route exact path="/admin/certificateTypes" component={CertificateTypes}/>
                <Route exact path="/admin/taxes" component={Taxes}/>
                <Route exact path="/admin/users" component={Users}/>
                <Route exact path="/admin/logout" component={LogOut}/>
                <Route exact path="/companyCertificate/:companyId/:pdf?" component={CompanyCertificate}/>
            </Switch>
          </div>
      </Router>
  );
}

export default App;
